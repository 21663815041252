.cta-area__wrapper {
    position: absolute;
    bottom: 0;
    right: -15px;
    z-index: 8;
}
/* safari fix */
@media screen and (min-width: 768px) {
    .cta-area__wrapper--in-hero {
        right:0;
        overflow: hidden;
    }
    .cta-area__wrapper--in-hero.is-sticky {
        top:0;
        bottom:auto;
        position: fixed;
    }
    .cta-area--in-hero {
        margin-right:-15px;
        padding-left:15px;
    }
}
.cta-area.cta-area--in-subnav {
    position: absolute;
    overflow: hidden;
    right: 0;
    bottom: 0;
    top: 0;
}
.cta-area--in-subnav .cta-area__item {
    position: relative;
    right: -15px;
}
.cta-area {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: end;
    margin-bottom: 0;
    padding: 0;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.cta-area__item:last-child {
    padding-right: 15px;
}
.cta-area__item {
    background-color: var(--color-primary);
    transform: skew(-20deg);
    display: block;
    height: 69px;
    transition: background-color 0.2s ease-in;
    @media screen and (min-width: 1460px) {
        height: 80px;
    }
}
.cta-area__item:hover {
    background-color: var(--color-primary-dark);
}
.cta-area__item:hover .cta-area__link,
.cta-area__link:focus,
.cta-area__link:visited,
.cta-area__link:active {
    color: #fff;
}
.cta-area__link {
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    transform: skew(20deg);
    display: block;
    padding: 20px;
    white-space: nowrap;
    @media screen and (min-width: 1600px) {
        padding: 24px 20px;
        font-size: calc(16rem/10);
    }
}


@media screen and (min-width: 1280px) and (max-width: 1599px) {
    .cta-area__link {
        padding: 20px 14px;
        font-size: calc(15rem/10);
    }
}

.cta-area--in-subnav .cta-area__link {
    @media screen and (min-width: 768px) and (max-width: 1279px){
        padding: 24px 12px;
        font-size: 12px;
    }
}

.cta-area__item + .cta-area__item {
    border-left: 1px solid #fff;
}

@media screen and (max-width: 767px) {
    .cta-area.cta-area--in-hero .cta-area__item {
        height: 40px;
    }
    .cta-area.cta-area--in-hero .cta-area__link {
        font-size: 12px;
        padding: 10px;
    }
}

/* CTA Area in footer - mobile */
.cta-area__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 35px;
    margin-right: 50px;
    height: auto;
    min-height: 59px;
    @media screen and (min-width:768px){
        display: none;
    }
}
.cta-area__footer__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
    min-height: 59px;
}
.cta-area__footer__item>.btn {
    font-size: 13px;
    height:100%;
    display: block;
    white-space: normal;
    padding: 7px 15px calc(7px + env(safe-area-inset-bottom)) 15px;
}
.cta-area__footer__item>.btn:hover,
.cta-area__footer__item>.btn:focus {
    background: var(--color-primary);
    border-color: var(--color-primary);
}
.cta-area__footer__item>.btn>.icon {
    vertical-align: -4px;
    font-size: 18px;
    margin-right: 8px;
}
.rtl-page .cta-area__footer__item>.btn>.icon {
    margin-right: 0;
    margin-left: 8px;
}
.cta-area__footer__item--w-100 {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.cta-area__footer__item--w-100>.btn{
    font-size: 14px;
    width: 100%;
    padding: 15px 15px calc(15px + env(safe-area-inset-bottom)) 15px;
}
.cta-area__footer__item + .cta-area__footer__item {
    border-left: 1px solid white;
}

/* sticky cta in footer contact-slide */

.is-fixed .cta-area__footer {
    min-height:auto;
    background: var(--color-primary-dark);
}
.is-fixed .cta-area__footer__item {
    min-height:auto;
}
.is-fixed .cta-area__footer__item>.btn,
.is-fixed .cta-area__footer__item>.btn:hover,
.is-fixed .cta-area__footer__item>.btn:focus {
    background: var(--color-primary-dark);
    border:0;
}