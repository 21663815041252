.ireport {
    position: fixed;
    top:50px;
    left:50%;
    padding:30px;
    width:300px;
    height:auto;
    margin:0 0 0 -150px;
    color:#fff;
    background: url(/static/img/backgrounds/skew-bg-red.png) #c91019 50% 50% no-repeat;
    background-size: cover;
    z-index:9999;

    @media screen and (min-width: 768px) {
        top:50%;
        padding:30px 65px;
        width:630px;
        margin:-120px 0 0 -315px;
    }
}

.ireport.emergency{
    background: #c91019;

}

.ireport-overlay {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,.75);
    z-index:9990;
}

.ireport-close {
    position: absolute;
    right:14px;
    top:8px;
}

@media screen and (min-width: 768px) {
    .ireport__btns .btn + .btn {
        margin-left:22px;
    }
}

@media screen and (max-width: 767px) {
    .ireport__btns .btn {
        display:block;
        margin:10px 0 0 0;
    }
}
