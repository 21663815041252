.pimcore_area_content table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
}

.pimcore_area_content table>tbody>tr:nth-of-type(odd) {
    background-color: #f1f1f1;
}

.pimcore_area_content table>tbody>tr>td {
    border-top: none;
}

.pimcore_area_content table>tbody>tr>td,
.pimcore_area_content table>tbody>tr>th,
.pimcore_area_content table>tfoot>tr>td,
.pimcore_area_content table>tfoot>tr>th,
.pimcore_area_content table>thead>tr>td,
.pimcore_area_content table>thead>tr>th {
    padding: 9px 22px 11px;
    line-height: 1.42857143;
    vertical-align: top;
}

.pimcore_area_content table>tbody>tr>td:first-child,
.pimcore_area_content table>tbody>tr>th:first-child,
.pimcore_area_content table>tfoot>tr>td:first-child,
.pimcore_area_content table>tfoot>tr>th:first-child,
.pimcore_area_content table>thead>tr>td:first-child,
.pimcore_area_content table>thead>tr>th:first-child {
    padding-left: 30px;
}