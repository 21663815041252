.ag-portalteaser {
    position: relative;
    box-shadow: 0 3px 3px rgba(0,0,0,.1);
    border: 1px solid #f1f1f1;
    margin-bottom:20px;
}
.ag-portalteaser__body {
    padding:20px 15px 15px 15px;
}
.ag-portalteaser__body-hl {
    margin-bottom: 10px;
}
.ag-portalteaser__links ul {
    padding-left: 20px;
    margin:0;
    @media screen and (min-width: 768px) {
        column-count: 2;
        column-gap: 40px;
    }
}