.search-input {
    position: relative;
}

.search-input__icon {
    position: absolute;
    top: 50%;
    left: 13px;
    z-index: 1;
    font-size: 18px;
    transform: translateY(-50%);
    @media (min-width: 768px) {
        left: 21px;
    }
}

.search-input__input {
    padding: 9px 12px;
    height: auto;
    padding-left: 40px;
    @media (min-width: 768px) {
        font-size: 20px;
        padding-left: 50px;
    }
}