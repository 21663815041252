.content-teaser__body {
    padding-top: 20px;
}
.content-teaser__body__hl {
    text-transform: none;
    font-size: 30px;
    line-height:calc(34/30);
    margin-bottom: 15px;
}
.content-teaser__body__text {
    line-height:calc(20/16);
    margin-bottom: 20px;
}