/*-----------allgemein-----------*/
.modal {
    z-index:1080;
}
.modal-backdrop {
    z-index: 1060;
}
.product-modal--wrapper .modal-content,
#product-modal .modal-content {
    border-radius: 0;
}
.product-modal--wrapper .modal-body,
#product-modal .modal-body {
    padding: 0;
    overflow: hidden;
    border-radius: 0;
}
@media screen and (min-width: 768px) {
    .modal-body {
        border-radius: 0;
    }
}
@media screen and (max-height: 800px) and (min-width: 768px) {
    .product-modal--wrapper .modal-dialog,
    #product-modal .modal-dialog {
        margin:50px auto;
    }
}

@media screen and (min-width: 992px) {
    /* this fixes bootstrap 3 und bootstrap 4 bugs */
    .product-modal--wrapper .hidden-sm,
    #product-modal .hidden-sm {
        display: block !important;
    }
}


/*------- text wrapper ----------*/
.product-modal__text {
    position: relative;
    line-height: 1.4;
    z-index: 5;
    background-color: white;
    padding: 45px 20px;
}
@media screen and (min-width: 768px) {
    .product-modal__text {
        font-size: 16px;
        line-height: 23px;
        padding: 45px 25px;
    }
}
@media screen and (min-width: 768px) {
    .product-modal__text--without-img {
        padding: 50px 120px;
    }
}

.product-modal__title {
    font-family: var(--font-bold-family);
    color: var(--color-primary);
    text-transform: none;

    font-size: 17px;
    line-height: 19px;
    margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
    .product-modal__title {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 10px;
    }
}



/*------------- img -----------*/
.product-modal__img {
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.product-modal__img--person {
    background-size:contain;
    background-position: 50% 0;
}
.product-modal__img:not(.product-modal__img--person):after {
    content: "";
    background: #fff;
    height: 1200px;
    width: 280px;
    display: block;
    position: absolute;
    right: -100px;
    top:-50px;
    transform: rotate(15deg);
    z-index: 1;
}
.rtl-page .product-modal__img:after {
    left: -100px;
    right: auto;
    transform: rotate(-15deg);
}

/*------------ form -----------*/
.product-modal__form {
    margin-top: 15px;
}
@media screen and (min-width: 768px) {
    .product-modal__form {
        margin-top: 30px;
    }
}

.product-modal__label {
    display: block;
}
@media screen and (max-width: 767px) {
    .product-modal__checkbox .checkbox-styled__text {
        font-size: 12px;

    }
    .product-modal__checkbox {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
@media screen and (min-width: 768px) {
    .product-modal__checkbox {
        margin-top: 30px;
        margin-bottom: 23px;
    }
}

/*--------- form messages -----------*/
.product-modal__form-submit {
    margin-top: 30px;
}
@media screen and (min-width: 768px) {
    .product-modal__form-submit {
        margin-top: 30px;
    }
}

.request-form__error {
    font-family: var(--font-bold-family);
    color: var(--color-primary);
    font-size: 12px;
    margin-bottom: 7px;
}
@media screen and (min-width: 768px) {
    .request-form__error {
        margin-bottom: 20px;
        font-size: 16px;
    }
}

.request-form__success {
    font-family: var(--font-bold-family);
    line-height: 1.2;
    font-size: 12px;
}
@media screen and (min-width: 768px) {
    .request-form__success {
        font-size: 16px;
    }
}

/*------------- button ----------*/
.product-modal__btn {
    width: 100%;
    margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
    .list-with-gutter--20>li.product-modal__btn {
        margin-top: 25px;
    }
}