.contact-slide {
  display: block;
  background-color: var(--color-primary);
  position: fixed;
  z-index: 999;
}
@media screen and (min-width: 768px){
  .contact-slide {
    position: relative;
    padding: 55px 0 30px;
    background-image: url('/static/img/backgrounds/bg-contact.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
  }
}
@media screen and (max-width: 767px){
  .contact-slide {
    bottom: 59px;
    height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
    transition: transform 200ms ease-in-out;
  }
  .contact-slide__content {
    padding-bottom: 80px;
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .is-fixed .affix-footer {
    transform: translateY(59px);
    z-index: 1050;
  }
}
.contact-slide__content > .col {
  margin-top: 40px;
}
@media screen and (min-width: 768px){
  .contact-slide > .col {
    margin-top: 0;
  }
  .contact-slide > .col:nth-child(2) {
    text-align: center;
  }
}

.contact-slide__content__hl {
    word-break: break-word;
}
@media screen and (max-width: 767px){
    .contact-slide__content .col {
        text-align: center;
        margin: 35px 0 60px 0;
    }
    /* hack for nicer style on mobile */
    .contact-slide__content__hl {
        max-width: 280px;
        width: 100%;
        margin-left:auto;
        margin-right:auto;
    }
    /* hack for nicer style on mobile */
    .contact-slide__content__hl br {
        display: inline;
        content: ' ';
        clear:none;
    }
}
@media screen and (min-width: 992px)  and (max-width: 1200px){
    .contact-slide__content__hl {
        font-size: 28px;
    }
}