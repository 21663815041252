.dealer-map {
    position: relative;
}

.dealer-map__map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.choose-filter__wrapper,
.choose-filter__wrapper:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
}

.choose-filter__wrapper:before {
    content: '';
    background-color: black;
    opacity: 0.5;
    z-index: 10;
}

.choose-filter__box {
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 300px;
    border: 1px solid #767676;
    background-color: #ffffff;
    padding: 30px;
    font-size: 16px;
    line-height: 20px;
}