@media screen and (max-width: 767px) {
    .text-with-media__media-container {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 768px) {
    .text-with-media__media {
        margin-bottom: 10px;
    }
}

.text-with-media__media .img-responsive {
    width:100%;
}

.figure-item {
    position: relative;
    overflow: hidden;
}