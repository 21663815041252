

.nav-tabs--dealersearch li.active a, .nav-tabs--dealersearch li.active:hover a {
    background-color: var(--color-primary);
    color:#fff;
}
.dealer-search {
    margin-top: 40px;
    margin-bottom: 60px;
    @media screen and (max-width: 767px) {
        margin-top: 0px;
        margin-bottom: 20px;

    }
}
.dealer-search .tab-content--dealersearch {
    background-color: var(--color-primary);
    color:#fff;
}
.tab-content--dealersearch .tab-pane {
    padding-bottom:0px;
}

.dealersearch__getlocation .icon {
    padding-right:4px;
    font-size:12px;
}
.dealersearch__location-type {
    background-color: #f1f1f1;
    height:60px;
    color: var(--color-dark);
    line-height:60px;
    margin-top:15px;
    @media screen and (max-width: 767px) {
        height:auto;
    }
}

.dealersearch__location-type__label {
    display:inline-block;
    font-weight:bold;
    @media screen and (max-width: 767px) {
        display:block;
    }
}

.dealersearch__location-type__checkbox {
    display:inline-block;
    margin-left:30px;
    @media screen and (max-width: 767px) {
        display:block;
        width: 50%;
        float:left;
        margin-left:0px;
    }
}

.dealersearch__form-col.step-width-1 {
    width:16.53%;
}
.dealersearch__form-col.step-width-button {
    width:16.5%;
}
.dealersearch__form-col.step-width-2 {
    width: 23.82%;
}
.dealersearch__form-col.step-width-4 {
    width: 43.15%;
}
.dealersearch .js-srcbutton {
    width:100%;
}

.dealer-search .select {
    color:#000;
}
.dealersearch__getlocation {
    margin-bottom:5px;
}

.dealersearch__form--step1.step-width-2 .form-group label {
    display:none;
}

.dealer-search .js-gotostep {
    width:100%;
    display:block;
}


.dealersearch__detaillist {
    margin-top:60px;
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
        margin-top:20px;
        margin-bottom: 0px;
    }
}
#dealermap {
    padding-bottom:50px;
}
.dealermap__poi-both {
    height: 16px;
}

#namesearch {
    padding-bottom:20px;
}

.form-companysearch {
    padding-bottom:20px;
    padding-left:30px;
}

.showplzerror {
    font-size:12px;
}
.dealer-search .has-success:before {
    display:none;
}
@media screen and (min-width: 768px) {
    #step2, #step4, #step-button{
        position:relative;
    }
    #step2:before, #step4:before, #step-button:before {
        content: "";
        background-image: url('/static/img/haendlersuche/step-arrow.png');
        display: block;
        position: absolute;
        left: -10px;
        width: 30px;
        height: 100px;
        top: 11px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    #step2.has-step-3 .dealersearch__form-group:first-of-type {
        padding-right: 20px;
    }
    #step2.has-step-3 .dealersearch__form-group {
        width: 50%;
        float:left;
    }
    .dealer-search .js-gotocompanysearch {
        margin-top: 30px;
    }
}

@media screen and (max-width: 767px) {
    .dealer-search .nav-tabs--dealersearch {
        padding-left:0px;
    }
    .dealer-search .nav-tabs--dealersearch >li {
        width:50%;
    }
    .dealer-search .nav-tabs--dealersearch >li.active a{
        background-color:var(--color-primary);
        color:#fff;
    }
    .dealer-search .nav-tabs--dealersearch >li >a {
        padding:17px 15px;
        height:100%;
        font-size:12px;
    }
    .dealersearch__form-col {
        float: left;
        width: 100% !important;
    }
    .js-finalstep, .js-gotostep {
        width:100%;
    }

}



.embed-responsive-dealermap {
    padding-bottom: 33.3333%;
    @media screen and (max-width: 767px) {
        padding-bottom: 150%;
    }
}

.dealer-map__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.65);
    z-index: 3;
}

.bussinesscardlink .icon {
    position: relative;
    top: 7px;
    padding-right: 3px;
    font-size: 25px;
    pointer-events: none;
}
.dealersearch__location-type {
    position:relative;
}
.dealersearch__loaction-type__error {
    position: absolute;
    width: 181px;
    top: 62%;
    text-align: left;
    color:  var(--color-danger);
}
.dealersearch__loaction-type__error:empty {
    display: none;
}

.dealer-search-block.active{
    position: relative;
    overflow: hidden;
}

.dealer-search-block.active:after{
    content: 'Due to technical maintenance our Sales and Service Partner Search is currently not available. Please try again later!';
    display: block;
    position: absolute;
    text-align: center;
    padding-top: 10px;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #c91019;
    color: #ffffff;
    opacity: 0.8;
}