.main-footer__external {
    background-color: #303030;
    background-image: url('/static/img/backgrounds/bg-overlay-grey.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 38px 0 20px;
}
@media screen and (min-width: 768px){
    .main-footer__external {
        background-position: 0 35%;
        padding: 67px 0 48px;
    }
}
.main-footer__external__list li {
    margin-bottom: 18px;
    font-size: 16px;
}
.main-footer__external .external-link:after,
.main-footer__external .internal-link:after,
.main-footer__external .external-link:before,
.main-footer__external .internal-link:before {
    color: var(--color-primary);
}

.main-footer__bottom {
    padding: 30px 0 70px;
}
.main-footer__bottom__list {
    font-size: 14px;
}
@media screen and (min-width: 768px){
    .main-footer__bottom {
        padding: 35px 0 20px;
    }
}

@media screen and (max-width: 767px) {
    .main-footer__bottom__list > li {
        margin-bottom: 18px;
    }
    .main-footer__bottom__list > li:first-child {
        display: block;
        margin-bottom: 15px;
    }
    .main-footer .at-icon,
    .main-footer .at-icon-wrapper {
        width: 40px !important;
        height: 40px !important;
    }
    .main-footer .at-resp-share-element.at-mobile .at-share-btn{
        padding: 1px;
        margin-right: 18px;
    }
}

@media screen and (min-width: 768px){
    .is-fixed .affix-footer {
        position: fixed;
        z-index:100;
        left: 0;
        right: 0;
        top: 100%;
        transform: translateY(-100%);
        transition: transform 200ms ease-in-out;
    }
    .is-fixed .affix-footer__buttons,
    .is-bottom .affix-footer__buttons,
    .affix-bottom.affix-footer__buttons {
        position: absolute;
        bottom: 100%;
        top: auto !important; /*overwrite inline style*/
    }
}

/*affix footer buttons*/
.affix-footer__buttons {
    position: absolute;
    z-index: 100;
    left: -50px;
    right: 0;
    padding-right: 50px;
    height: 60px;
    overflow: hidden;
    transform: translateZ(0) translateX(50px);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px){
        position: fixed;
        bottom: 0;
        left:auto;
        height: 50px;
    }
}

.affix.affix-footer__buttons,
.affix-bottom.affix-footer__buttons,
.is-fixed .affix-footer__buttons {
    transform: translateZ(0) translateX(0);
}

.affix-footer__buttons .btn {
    @media screen and (min-width: 768px){
        height: 50px;
        padding-top: 12px;
    }
}

@media screen and (max-width: 767px){
    .affix-footer__buttons__right .btn-icon--lg {
        min-height: 59px;
    }
    .is-fixed .affix-footer__buttons__right .btn-icon--lg {
        min-height: 50px;
    }
}
.is-fixed .affix-footer__buttons__right {
    transform: translateY(-50%);
}
.affix-footer__buttons__right {
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 200ms ease-in-out;
    transition-delay: 50ms;
    z-index: 1;
}


/*contact button*/
.affix-footer__buttons__contact {
    padding: 10px 18px;

    @media screen and (max-width: 767px) {
        position: absolute;
        top: 0;
        width: 100%;
        padding: 17px 50px 19px 0;
        background: var(--color-primary-dark);
        border-color: var(--color-primary-dark);
        z-index: 1;
        transition: transform 200ms ease-in-out;
    }
}
.affix-footer__buttons__contact>.icon {
    vertical-align: -3px;
    font-size: 18px;
    margin-right: 5px;
    margin-left: 5px;
}
@media screen and (max-width: 767px){
    .affix .affix-footer__buttons__contact,
    .is-fixed .affix-footer__buttons__contact {
        transform: translateX(25px);
    }
}

/* scroll top button for ag */
.affix-footer--ag {
    display: block;
    position: relative;
}
@media screen and (max-width: 767px) {
    .affix-footer--ag {
        position: fixed;
        bottom: 49px;
        right: 0;
    }
}