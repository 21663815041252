.key-figures {
    position: relative;
    display: inline-block;
    width: 100%;
    justify-content: center !important;
}
.col--keyfigures {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: 991px) {
        float:left;
        width:20%;
    }
}
.key-figures__item {
    position: relative;
    margin:0 0 30px 0;
    line-height:1.33;

    @media screen and (min-width: 991px) {
        margin: 30px auto;
    }

    @media screen and (max-width: 767px) {
        margin-bottom:75px;
    }
}
.key-figures__circle {
    color:#fff;
    display: block;
    width: 180px;
    height:180px;
    padding:45px 15px 0 15px;
    border-radius: 50%;
    background: url(/static/img/backgrounds/bgCircle.png) 50% 50% no-repeat;
    background-size: cover;
    margin:0 auto 15px auto;

    @media screen and (min-width: 991px) {
        margin-bottom:25px;
    }

    @media screen and (min-width:1200px) {
        width: 200px;
        height:200px;
        padding-top:55px;
    }
}
.key-figures__main {
    margin-bottom:12px;
}
.key-figures__sub {
    padding-top:12px;
    border-top:1px solid #fff;
    font-family: var(--font-bold-family);
}
.key-figures__body {
    margin-bottom:15px;
}

/* mobile slider dots */
.key-figures li:not(.slick-active) .slick-dots__btn:after {
    background: #ccc;
}
.key-figures .slick-dots__btn {
    color: var(--color-dark);
}