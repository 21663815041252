.offer-info+ .offer-info {
    margin-top: 5px;

    @media screen and (min-width: 768px) {
        margin-top: 15px;
    }
}
.offer-info__price {
    font-size: 25px;
    line-height: 1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    background: #fff;
    padding: 7px 12px 12px;
    margin-bottom: 2px;

    @media screen and (min-width: 768px) {
        font-size: 36px;
        padding: 1px 12px 7px;
    }
}
.offer-info__label {
    font-size: 14px;
}
.offer-info__hl{
    font-size: 26px;
    line-height: 1;
}
.offer-info--sm .offer-info__hl {
    font-size: 20px;
}