.btn-icon {
    position: relative;
    width:34px;
    height: 34px;
    padding: 5px;
}
.btn-icon--lg {
    width: 50px;
    height: 50px;
    font-size: 18px;
}
.btn-icon__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.btn-icon--skew {
    position: absolute;
    bottom: 0;
    right:-10px;
    height: 24px;
    width: 40px;
    transform: skew(-34deg);

    @media screen and (min-width:768px){
        width: 57px;
        height: 34px;
    }
}
.btn-icon--skew>.btn-icon__icon {
    transform: skew(34deg) translateY(-50%) translateX(-50%);
    font-size: 8px;
    @media screen and (min-width:768px) {
        font-size: 11px;
    }
}
.rtl-page .btn-icon--skew {
    left: -11px;
    right: auto;
    transform: skew(34deg);
}
.rtl-page .btn-icon--skew>.btn-icon__icon {
    transform: skew(-34deg) translateY(-50%) translateX(-50%) scaleX(-1);
}