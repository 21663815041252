/** @define Arrange */

:root {
    --Arrange-gutter-size: 10px;
}

/**
 * This component lets you lay out a row of cells in various ways. You can
 * specify whether a cell should be wide enough to fit its content, or take up
 * the remaining space in the row. It's also possible to give all cells an
 * equal width, and to control their vertical alignment.
 */

/**
 * 1. Protect against the component expanding beyond the confines of its
 *    container if properties affecting the box-model are applied to the
 *    component. Mainly necessary because of (5).
 * 2. Rely on table layout.
 * 3. Zero out the default spacing that might be on an element (e.g., `ul`).
 * 4. Make sure the component fills at least the full width of its parent.
 * 5. Reset the table-layout algorithm in case a component is nested.
 */

.Arrange {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; /* 1 */
    display: table; /* 2 */
    margin: 0; /* 3 */
    min-width: 100%; /* 4 */
    padding: 0; /* 3 */
    table-layout: auto; /* 5 */

    /*outline: 1px solid blue;*/
}

/**
 * There are two possible types of child. `sizeFill` will expand to fill all
 * of the remaining space not filled by `sizeFit` elements.
 *
 * 1. Zero out any default spacing that might be on an element (e.g., `li`);
 *    Margin has no effect when coupled with `display: table-cell`.
 * 2. All cells are top-aligned by default
 */
.Arrange-sizeFill.Arrange-sizeFill,
.Arrange-sizeFit.Arrange-sizeFit {
    display: table-cell;
}

.Arrange-sizeFill,
.Arrange-sizeFit {
    display: table-cell;
    padding: 0; /* 1 */
    vertical-align: top; /* 2 */

    /*outline: 1px solid lightcoral;*/
}

/**
 * Make sure the main content block expands to fill the remaining space.
 */

.Arrange-sizeFill {
    width: 100%;
    /*outline: 1px solid lightgreen;*/
}

/**
 * Where possible, protect against large images breaking the layout. Prevent them from
 * exceeding the width of the main content block by making them fluid.
 *
 * Only work for all browsers with the `Arrange--equally` variant. For Firefox
 * and IE to constrain image dimensions for other layouts, large images will
 * need their width set to `100%`.
 */

.Arrange-sizeFill>img {
    height: auto;
    max-width: 100%;
}

/**
 * Defend against a side-effect of this layout pattern: images in
 * 'Arrange-sizeFit' cannot be fluid, otherwise they lose their ability to
 * provide size to a cell.
 */

/*.Arrange-sizeFit img {*/
    /*max-width: none !important;*/
    /*width: auto !important;*/
/*}*/

.Arrange-sizeFill--with-truncate {
    max-width: 1px;
}

/* Vertical alignment modifiers
   ========================================================================== */

.Arrange--middle > .Arrange-sizeFill,
.Arrange--middle > .Arrange-sizeFit {
    vertical-align: middle;
}

.Arrange--bottom > .Arrange-sizeFill,
.Arrange--bottom > .Arrange-sizeFit {
    vertical-align: bottom;
}

/* Equal-width modifier
   ========================================================================== */

/**
 * This layout algorithm will create equal-width table cells, irrespective of
 * the width of their content.
 *
 * 1. The layout algorithm requires a set width to correctly calculate table
 *    cell width.
 */

.Arrange--equal {
    table-layout: fixed;
    width: 100%; /* 1 */
}

/**
 * Give the cells an equal width. This value ensures that Arrange is still 100%
 * wide when gutters are used in conjunctions with equal-width cells.
 *
 * It's recommended that only 'Arrange-sizeFill' be used for equal width cells.
 * Their inner images will automatically be responsive.
 */

.Arrange--equal > .Arrange-sizeFill,
.Arrange--equal > .Arrange-sizeFit {
    width: 1%;
}

/* Gutter modifier
   ========================================================================== */

/**
 * Add a gutter between cells
 *
 * NOTE: this can trigger a horizontal scrollbar if the component is as wide as
 * the viewport. Use padding on a container, or `overflow-x:hidden` to protect
 * against it.
 */

.Arrange--withGutter {
    margin: 0 calc(-0.5 * var(--Arrange-gutter-size));
}

.Arrange--withGutter > .Arrange-sizeFit,
.Arrange--withGutter > .Arrange-sizeFill {
    padding: 0 calc(0.5 * var(--Arrange-gutter-size));
}

.Arrange--withGutter-36 {
    margin: 0 calc(-0.5 * 36px);
}
.Arrange--withGutter-36 > .Arrange-sizeFit,
.Arrange--withGutter-36 > .Arrange-sizeFill {
    padding: 0 calc(0.5 * 36px);
}

.Arrange--withGutter-40 {
    margin: 0 calc(-0.5 * 40px);
}
.Arrange--withGutter-40 > .Arrange-sizeFit,
.Arrange--withGutter-40 > .Arrange-sizeFill {
    padding: 0 calc(0.5 * 40px);
}

.Arrange--withGutter-16 {
    margin: 0 calc(-0.5 * 16px);
}
.Arrange--withGutter-16 > .Arrange-sizeFit,
.Arrange--withGutter-16 > .Arrange-sizeFill {
    padding: 0 calc(0.5 * 16px);
}

.Arrange--withGutter-20 {
    margin: 0 calc(-0.5 * 20px);
}
.Arrange--withGutter-20 > .Arrange-sizeFit,
.Arrange--withGutter-20 > .Arrange-sizeFill {
    padding: 0 calc(0.5 * 20px);
}

.Arrange--withGutter-6 {
    margin: 0 calc(-0.5 * 6px);
}
.Arrange--withGutter-6 > .Arrange-sizeFit,
.Arrange--withGutter-6 > .Arrange-sizeFill {
    padding: 0 calc(0.5 * 6px);
}

.Arrange--withGutter-8 {
    margin: 0 calc(-0.5 * 8px);
}
.Arrange--withGutter-8 > .Arrange-sizeFit,
.Arrange--withGutter-8 > .Arrange-sizeFill {
    padding: 0 calc(0.5 * 8px);
}

.Arrange--withGutter-10 {
    margin: 0 calc(-0.5 * 10px);
}
.Arrange--withGutter-10 > .Arrange-sizeFit,
.Arrange--withGutter-10 > .Arrange-sizeFill {
    padding: 0 calc(0.5 * 10px);
}

@media screen and (max-width: 767px) {
    .Arrange--disableOnXs,
    .Arrange--disableOnXs > .Arrange-sizeFill,
    .Arrange--disableOnXs > .Arrange-sizeFit {
        display: block;
    }

}

@media screen and (max-width: 1199px) {
    .Arrange--disableOnSM,
    .Arrange--disableOnSM > .Arrange-sizeFill,
    .Arrange--disableOnSM > .Arrange-sizeFit {
        display: block;
    }

}


/*Arrange--flex*/
.Arrange--flex {
    display: flex;
    align-items: stretch;
    /*flex-direction: row;*/

}
.Arrange--flex > .Arrange-sizeFit {
    display: block;
    width: auto;
    max-width: 100%;
}
.Arrange--flex > .Arrange-sizeFill {
    display: block;
    width: auto;
    flex:1 0 auto;
    max-width: 100%;
}
.Arrange--flex.Arrange--middle > .Arrange-sizeFill,
.Arrange--flex.Arrange--middle > .Arrange-sizeFit {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Arrange--flex.Arrange--bottom > .Arrange-sizeFill,
.Arrange--flex.Arrange--bottom > .Arrange-sizeFit {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.Arrange--flex.Arrange--equal > .Arrange-sizeFill,
.Arrange--flex.Arrange--equal > .Arrange-sizeFit {
    flex: 1 0 1px;
}