.embed-responsive-item.video-js {
    /*override video.js inline style*/
    width: 100% !important;
    height: 100% !important;
}
.vjs-poster{
    object-fit: cover;
    background-size: cover;
}
.vjs-default-skin.vjs-default-skin {
    color: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    color: #ffffff;
    border-radius: 999px;
    border: 2px solid;
    background-color: rgba(30, 40, 50, 0.5);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    font-size: 3.9em;
}
.vjs-default-skin.vjs-default-skin .vjs-big-play-button:before {
    width: auto;
    height: auto;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-45%) translateY(-50%);
    text-shadow: none;
}
.vjs-default-skin.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin.vjs-default-skin .vjs-big-play-button:focus {
    background: var(--color-primary);
}
@media screen and (max-width: 767px) {
    .vjs-default-skin.vjs-default-skin .vjs-big-play-button {
        margin-top: -20px;
        margin-left: -20px;
        width: 40px;
        height: 40px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    }
    .vjs-default-skin.vjs-default-skin .vjs-big-play-button:before {
        font-size: 13px;
        transform: translate(-35%,-50%);
    }
}


.vjs-default-skin.vjs-default-skin.vjs-has-started .vjs-control-bar {
    background: #57564a;
    background: rgba(87, 86, 74, 0.8);
}
.vjs-default-skin.vjs-default-skin .vjs-play-progress,
.vjs-default-skin.vjs-default-skin .vjs-volume-level {
    background: var(--color-primary);
}
.vjs-default-skin.vjs-default-skin .vjs-slider-handle:before {
    text-shadow: none;
    color: #3d3d3f;
}
.vjs-default-skin.vjs-default-skin .vjs-load-progress {
    background: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-progress-control {
    font-size: .6em;
}
.vjs-default-skin.vjs-default-skin .vjs-control {
    color: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-control:focus:before,
.vjs-default-skin.vjs-default-skin .vjs-control:hover:before {
    text-shadow: none;
    opacity: .9;
}
.vjs-default-skin.vjs-default-skin .vjs-volume-bar {
    font-size: .8em;
    background: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-volume-level {
    height: .73em;
}