@media (min-width: 768px) {
    .product-description__right-col {
        padding-left: 30px;
        border-left: 1px solid var(--color-grey);
        padding-bottom: 15px;
    }

    .product-description__left-col {
        padding-right: 30px;
    }
    #modelleDetail-description .slide {
        overflow: hidden;
    }
}

.product-decription__price {
    font-size: 36px;
    line-height: 1;
}

.products__buttonarea {
    position: relative;
    padding:35px;
    background-color: #f0f0f0;

    @media screen and (min-width: 768px){
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
    }

    @media screen and (max-width: 767px){
        margin-left:-15px;
        margin-right:-15px;
    }
}

.products__buttonarea .btn {
    margin:3px 10px;

    @media screen and (max-width: 767px){
        margin:0 0 12px 0;
        width:85%;
    }
}
/*
.products__buttonarea:before {
    display: block;
    content:' ';
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    width: 100vw;
    height:auto;
    top:0;
    bottom:0;
    background-color: #f0f0f0;
    z-index:1;
}
.products__buttonarea-inner {
    display: block;
    position: relative;
    z-index:2;
}*/
