table tr td.technical-data__abbr {
  min-width: 100px;
  padding-right: 15px;
}
.product-detail-img{
    border: 1px solid #d9dbdb;
}

.product-detail-img img{
    margin: 0 auto;
}