.lightboxgallery-teaser {
    display: block;
    position: relative;
    text-align: left;
}
.rtl-page .lightboxgallery-teaser {
    text-align: right;
}
.lightboxgallery-teaser__content {
    padding:20px 10px;
    line-height:1.5;
    /*
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    z-index: 1;*/
}
.lightboxgallery-teaser__content__hl {
    margin-bottom: 10px;
    font-size: 20px;
    line-height:1;
  @media screen and (min-width: 1200px){
    font-size: 25px;
  }
}
/*.lightboxgallery-teaser__img:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #000;
    opacity: .3;
    transition: opacity 350ms ease-in-out;
    transform: translateZ(0);
}*/
/*.lightboxgallery-teaser__img:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 2px solid var(--color-primary);
    z-index: 2;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}*/
/*.lightboxgallery-teaser:hover .lightboxgallery-teaser__img:after {
    opacity: 0;
}*/
/*
.lightboxgallery-teaser:hover .lightboxgallery-teaser__img:before {
    opacity: 1;
}*/
