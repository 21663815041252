.tooltip-badge {
    border: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    position: relative;
    display: inline-block;
    margin-left: 6px;
    vertical-align: -0.15em;
    padding: 0;
}
.tooltip-badge__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 10px;
}
.checkbox-styled .tooltip-badge {
    float: right;
    margin-top: 2px;
}
.checkbox-styled .tooltip {
    min-width: 240px;
}
.product-teaser .tooltip {
    max-width: 100%;
    white-space: nowrap;
}