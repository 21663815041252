.quiz-element + .quiz-element {
    margin-top:60px;
    @media (min-width: 768px) {
        margin-top:100px;
    }
}
.quiz-element:last-of-type {
    margin-bottom:60px;
    @media (min-width: 768px) {
        margin-bottom:100px;
    }
}
.btn.quiz-element__btn {
    min-width:150px;
    position: relative;
    display: inline-block;
    margin:10px;
    padding-left:12px;
    padding-right:12px;
    outline: 0 !important;
    @media (min-width: 768px) {
        min-width:200px;
        padding-left:30px;
        padding-right:30px;
    }
}
.quiz-element__btn > .icon {
    display: none;
    position: absolute;
    left:12px;
    top:12px;
    @media (min-width: 768px) {
        left:20px;
        top:14px;
    }
}


/* styles for correct answer */
.quiz-element--completed {
    pointer-events: none;
}

.quiz-element--completed .quiz-element__btn.is-answered-false {
    color:#fff;
    background:var(--color-danger);
    border-color:var(--color-danger);
}
.quiz-element--completed .quiz-element__btn.is-answered-false > .icon-plus {
    font-size:19px;
    display: inline-block;
    transform: rotate(45deg);
    top:10px;
    @media (min-width: 768px) {
        top:12px;
    }
}

.quiz-element--completed .quiz-element__btn.is-answered-right {
    color:#fff;
    background:var(--color-success);
    border-color:var(--color-success);
}
.quiz-element--completed .quiz-element__btn.is-answered-right > .icon-checked {
    display: inline-block;
}