.nav-tabs {
    padding-left: 11px;
    margin-left: 0;
}

.nav-tabs li a,
.nav-tabs__item {
    transform: skew(-20deg);
    cursor: pointer;

    @media screen and (max-width: 767px){
        font-size:13px;
        padding:18px 12px;
    }
}
.rtl-page .nav-tabs li+li>a {
    border-left: 1px solid #d9dbdb;
}
.nav-tabs__text {
    display: block;
    transform: skew(20deg);
    /* fixes blurry text (translateZ(0) would also work) */
    backface-visibility: hidden;
    font-weight: bold;
}

.nav-tabs__text__img {
    max-height: 46px;
    width: auto;
    margin: -16px 0 -13px 0;
}

.nav-tabs.nav-tabs--images>li>a {
    padding: 15px 30px;
}

.nav-tabs-container {
    overflow: hidden;
}
.nav-tabs-container__content {
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (min-width: 768px){
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.nav-tabs---full-border{
    position: relative;
    border-bottom: 0;
    padding-left: 0;
    margin-left: 0;
}
.nav-tabs---full-border:before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: var(--color-grey-light);
    left: -9999px;
    right: -9999px;
    bottom: 0;
}
.nav-tabs---full-border li {
    margin-bottom: 0;
}
.nav-tabs---full-border li:first-child {
    @media screen and (max-width:767px){
        padding-left: 11px;
    }
}

.tab-pane--padding-top {
    padding-top: 30px;

    @media screen and (min-width:768px){
        padding-top: 60px;
    }
}
.tab-pane--padding {
    padding: 30px 0;

    @media screen and (min-width:768px){
        padding: 60px 0 90px;
    }
}
.tab-pane--padding-small {
    padding: 30px 0;
}

@media screen and (max-width: 767px) {
    .nav-tabs.nav-tabs--images {
        white-space: nowrap;
        /* make the element scrollable... */
        overflow-x: auto;
        /* ... and make it start at the very left of the container (compensate container's padding)  ... */
        margin-left: -15px;
        /* ...make the overflowing part (left tip) visible */
        padding-left: 15px;
        /* fixes the whitespace between the inline-blocks*/
        font-size: 0;
    }

    .nav-tabs.nav-tabs--images>li {
        float: none;
        display: inline-block;
    }

    .nav-tabs---full-border:before {
        left: 0;
        right: 0;
    }
}

/* centered tabs */
.nav-tabs--centered {
    display: flex;
    justify-content: center;
}

/* overlay tabs */
.nav-tabs--filter {
    position: absolute;
    margin-top: -11px;
    transform: translateY(-100%);
    border-bottom: 0;
    left: 0;
    right: 0;
}
.nav-tabs--filter li>a {
    width: 150px;
    height: 100%;
    text-align: center;
    font-size: 13px;
    line-height: calc(15/13);
    transform: skew(-10deg);
    padding: 0;
    display: flex;
    flex-direction: column;
}
.nav-tabs--filter__title {
    padding: 5px 15px;
    font-family: var(--font-bold-family);
    font-weight: 400;
    flex-grow: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
li.active .nav-tabs--filter__title {
    background: var(--color-primary);
}
.nav-tabs--filter li.active a,
.nav-tabs--filter li.active a:hover,
.nav-tabs--filter li.active a:focus {
    border: 1px solid var(--color-primary);
    color: #fff;
}
.nav-tabs--filter__title__text {
    transform: skew(10deg);
    display: block;
}
.nav-tabs--filter__title__img {
    height: 50px;
    width: auto;
    transform: skew(10deg);
    margin-top: 12px;
    margin-bottom: 10px;
}
.nav-tabs--icons {
    font-size: 24px;
}

.nav-tabs--icons.nav-tabs > li .nav-tabs__item,
.nav-tabs--icons.nav-tabs > li a {
    line-height: 1;
}

.nav-tabs-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9dbdb;
    align-items: center;
}

.nav-tabs-row--end {
    justify-content: flex-end;
}

.nav-tabs-row .nav-tabs {
    border: none;
    padding-right: 11px;
}

@media screen and (min-width: 768px){
    .nav-tabs-row .nav-tabs {
        flex: 0 0 auto;
        align-self: flex-end;
    }
}