.google-map__infobox {
  background: #fff;
  padding: 30px;
  border: 1px solid #767676;
  line-height: calc(20/16);
  font-size: 16px;
  color: var(--color-dark);
}


.google-map__infobox .google-map__infobox__img {
  max-width: 100%;
}

.directions-link .icon-navigation {
  font-size: 12px;
  pointer-events: none;
}

/* close button */
.google-map__infobox > img {
  position: absolute !important;
  right: 7px;
  top: 7px;
}
.rtl-page .google-map__infobox > img {
  left: 7px;
  right: auto;
}

.google-map__infobox:after, .google-map__infobox:before {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -9px;
  top: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 9px;
  border-color: transparent transparent #ffffff transparent;
}
.google-map__infobox:before {
  margin-left: -10px;
  top: -10px;
  border-width: 0 10px 10px;
  border-color: transparent transparent #767676 transparent;
}


.google-map__infobox__content h3 {
    font-size:22px;
}

@media screen and (max-width: 767px){
  .google-map__infobox {
    padding: 5px 15px 15px 15px;
  }
  .google-map__infobox__content h3 {
    font-size:16px;
    margin-bottom:10px;
  }
  .google-map__infobox__content .btn.mt30 {
    margin-top:18px;
  }
}