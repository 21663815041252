.datepicker {
    position: relative;
}
.datepicker .form-control {
    padding-right: 40px;
}
.datepicker:before {
    pointer-events: none;
    content: var(--icon-calendar);
    font-family:iconfont;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #666;
    font-size: 21px;
}
.datepicker .form-control:focus{
    border-color: #000;
}
.datepicker .form-control{
    border: 1px solid #000;
}
.datepicker .form-control-label{
    left: 20px;
}
.datepicker .form-control:focus ~ .form-control-label{
    transform: translateY(-30px) translateX(-20px) scale(.85);
}
.ui-datepicker {
    min-width: 280px;
    background: #fff;
    padding: 0;
    z-index:100 !important;
    box-shadow: 0 0 6px rgba(0,0,0, .2);
}
.ui-datepicker-header {
    background: var(--color-primary);
    color: #fff;
}
.ui-datepicker th {
    padding:.3em;
    font-weight:300;
    font-family:var(--font-bold-family);
}
.ui-datepicker .ui-state-default {
    text-align: center;
    width: 32px;
    height: 32px;
}
.ui-datepicker .ui-state-hover {
    background: var(--color-grey-light);
}

.ui-datepicker .ui-state-disabled{
    opacity: 0;
}
.ui-datepicker .ui-state-active {
    background: var(--color-primary);
    color: #fff;
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
    top: 0;
    bottom: 0;
    height: auto;
}
.ui-datepicker-next.ui-state-hover,
.ui-datepicker-prev.ui-state-hover {
    background: transparent;
    color: #000;
}
.ui-datepicker .ui-datepicker-prev span {
    margin-top: -9px;
}
.ui-datepicker .ui-datepicker-next span {
    margin-top: -7px;
}
.ui-datepicker .ui-datepicker-prev span:before {
    transform: rotate(180deg);
    display: block;
}
.ui-datepicker-calendar th, .ui-datepicker-calendar td {
    color: black;
}


/* has calendar icon */

.has-calendar-icon {
    position: relative;
}

.has-calendar-icon:after {
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    right: 11px;
    top: 40px;
    pointer-events: none;
}

.rtl-page .ui-datepicker-rtl .ui-datepicker-prev {
    right: -3px;
}

.rtl-page .ui-datepicker-next .icon:before {
    content:var(--icon-arrow-left);
}
.rtl-page .ui-datepicker-prev .icon:before {
    content:var(--icon-arrow-left);
}
