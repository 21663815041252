.lg-backdrop {
  z-index:1060;
  background: var(--color-dark);
  opacity: .95;
}
.lg-outer {
  z-index:1065;
}
.lg-actions .lg-next:before,
.lg-actions .lg-prev:after {
  content: none;
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
  width: 35px;
  height: 66px;
  background: #d4d4d4;
  font-size: 26px;
  border-radius: 0;
  text-align: center;
  padding: 20px 10px;
  margin-top:-33px;
}
.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  transform: scale(1.1);
}
.lg-toolbar {
  background: transparent;
}
.lg-sub-html {
    background: rgba(0,0,0,.5);
    pointer-events: none;
}
@media screen and (min-width: 1800px) {
    .lg-outer .lg-video-cont {
        max-width: 1200px !important;
    }
}
.lightbox-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: var(--color-primary);
  font-size: 50px;
  background:transparent;
  border:0;
  -webkit-appearance: none;
  z-index:10;

  @media screen and (min-width: 768px){
    font-size: 70px;
  }
}
.lightbox-video-btn:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #fff;
  z-index: -1;
}
.lightbox-video-btn:hover {
  color: var(--color-primary-dark);
}
.lg-toolbar .lg-close.lg-close {
    font-family: 'iconfont';
}
.lg-toolbar .lg-close.lg-close:after {
    content:var(--icon-close);
    font-size:18px;
}
.lg-video video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    object-fit: contain;
}

/* RTL */
.rtl-page .lg-toolbar .lg-icon {
  float: left;
}