.page-heading {
    padding-top: 10px;
    padding-bottom: 30px;
}
.page-heading.page-heading--small-padding-bottom {
    padding-bottom: 15px;
}
.page-heading.page-heading--small-padding-top {
    padding-top: 10px;
}
@media screen and (min-width: 768px){
    .page-heading {
        padding-top: 40px;
    }
    .page-heading.page-heading--small-padding-bottom {
        padding-bottom: 20px;
    }
    .page-heading.page-heading--small-padding-top {
        padding-top: 10px;
    }
}

@media screen and (max-width: 767px) {
    
    .page-heading.page-heading--no-padding-xs {
        padding: 0;
    }
    
}

.page-heading__label{
    padding: 3px 6px 6px;
    margin-bottom: 10px;
    line-height: 1;
}