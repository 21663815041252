.btn-area {

}
.btn-area .btn {
    min-width:22%;
    margin:12px 12px 6px 0;

    @media screen and (max-width:767px){
        min-width:0;
        display:block;
        width:100%;
        margin:12px 0;
    }
}