.pimcore_area_flexible-text-media {
    position: relative;
    overflow: hidden;
}

.slide.slide--ftm {
    position: relative;
    padding-top:30px;
    padding-bottom:0;
    @media screen and (max-width: 767px){
        padding-bottom:30px;
        margin-bottom: 30px;
    }
    @media screen and (min-width: 768px){
        padding-top:85px;
        margin-bottom: 100px;
    }
    @media screen and (min-width: 1400px){
        padding-top:140px;
        margin-bottom: 180px;
    }
}

.slide.slide--ftm-media-medium {
    @media screen and (min-width: 768px){
        padding-bottom:85px;
    }
    @media screen and (min-width: 1400px){
        padding-bottom:140px;
    }
}

/* small spacings */
.slide.slide--ftm-media-medium.slide--ftm-spacing-bottom-small,
.slide.slide--ftm-media-medium.slide--ftm-spacing-top-small {
    @media screen and (min-width: 768px){
        padding-bottom:50px;
        padding-top:50px;
    }
    @media screen and (min-width: 1400px){
        padding-bottom:70px;
        padding-top:70px;
    }
}
.slide.slide--ftm-spacing-bottom-small {
    @media screen and (min-width: 768px){
        margin-bottom: 50px;
    }
    @media screen and (min-width: 1400px){
        margin-bottom:90px;
    }
}
.slide.slide--ftm-spacing-top-small:not(.slide--ftm-media-medium) {
    @media screen and (min-width: 768px){
        padding-top:40px;
    }
    @media screen and (min-width: 1400px){
        padding-top:70px;
    }
}

.slide--ftm-grey {background:#F7F7F8;}
.slide--ftm-white {background:#fff;}
.slide--ftm-black {background:#000; color:#fff;}

.container--ftm {
    max-width: 1570px;
}

.ftm__media-img {
    display: block;
    height:auto;
    width: 100%;
}
.ftm__media {
    position: relative;
    @media screen and (max-width: 767px){
        margin-bottom: 0;
        margin-top: 15px;
    }
    @media screen and (min-width: 768px){
        margin-bottom: -40px;
    }
    @media screen and (min-width: 1400px){
        margin-bottom: -70px;
    }
}

@media screen and (max-width: 767px){
    .ftm {
        padding:0 15px;
    }
    .ftm__text {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 767px){
    /* medium image adaptions */
    .ftm__media.ftm__media--medium {
        margin: 15px 30px 0 30px;
    }
}

@media screen and (min-width: 768px){
    /* medium image adaptions */
    .ftm__media.ftm__media--medium {
        margin: 0;
        position: absolute;
        right:0;
        top:0;
        bottom:0;
        width: 50%;
        height: auto;
    }
    .ftm__media.ftm__media--medium.ftm__media--right {
        right:auto;
        left:0;
    }
    .ftm__media--medium .ftm__media-video,
    .ftm__media--medium .ftm__media-img {
        display: block;
        width: 100%;
        height:100%;
        object-fit: cover;
    }
}

.ftm__media-video {
    position: relative;
    padding-bottom: 56.25%;
    display: block;
    height: 0;
    overflow: hidden;
    @media screen and (min-width: 768px){
        padding-right:20px;
        padding-left:0;
    }
}

.ftm__media--medium .ftm__media-video {
    @media screen and (min-width: 768px){
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        width: 100%;
        height:100%;
        padding:0;
    }
}

.ftm__media-video .pimcore_tag_video,
.ftm__media-video iframe,
.ftm__media-video video {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width: 100%;
    height:100%;
    object-fit: cover;
}
.ftm__media-video[hidden] {
    display: none;
}

.ftm__text {
    color:inherit;
    @media screen and (min-width: 768px){
        padding-right:20px;
        padding-left:0;
    }
    @media screen and (min-width: 1400px){
        padding-left:100px;
        padding-left:0;
    }
}

.order-col-last .ftm__text {
    @media screen and (min-width: 768px){
        padding-left:20px;
        padding-right:0;
    }
    @media screen and (min-width: 1400px){
        padding-left:100px;
        padding-right:0;
    }
}

.ftm__headline {
    margin-bottom:15px;
}

.ftm__button {
    margin:15px 0;
    @media screen and (min-width: 768px){
        margin-right:30px;
    }
}


/*
    helper classes for ftm area,
    because of different bootstrap versions of subsites
 */
.ftm-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.ftm-col-4,
.ftm-col-6,
.ftm-col-8 {
    max-width: 100%;
    padding-right:15px;
    padding-left:15px;

    @media screen and (max-width: 767px){
        width: 100%;
    }
}
@media screen and (min-width: 768px){
    .vertical-center {
        align-self: center;
    }
    .ftm-col-4 {
        flex: 0 0 auto;
        width: 33.3333%;
    }
    .ftm-col-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .ftm-col-8 {
        flex: 0 0 auto;
        width: 66.6666%;
    }
    .order-col-first {
        order:1;
    }
    .order-col-last {
        order:2;
    }
}
