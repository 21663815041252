.filter-sidebar {
    @media screen and (max-width: 767px){
        position: fixed;
        left: 0;
        right: 0;
        top:0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 1051;
        transform: translateX(100%);
        transition: transform 250ms ease-in-out;
        background: white;
    }
}

.filter-sidebar.is-open {
    transform: translateX(0);
}
.filter-sidebar__close {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
}

.filter-sidebar__title {
    padding: 19px 20px;
}

.filter-sidebar__block {
    border: 1px solid var(--color-grey-light);
    border-top: none;
    padding: 20px 19px;
}

.filter-sidebar__block__heading {
    position: relative;
    font-size: 16px;
    line-height: 1;
}

.filter-sidebar__block__body {
    padding-top: 17px;
}

.filter-sidebar__block__heading > a{
    display: flex;
    justify-content: space-between;
}

.filter-sidebar__block__collapse-icon:before {
    display: block;
    font-size: 10px;
    transform: rotate(180deg);
    transition: transform 250ms ease-in-out;
}
.collapsed .filter-sidebar__block__collapse-icon:before {
    transform: rotate(0);
}

.filter-sidebar__list {
    line-height: 1.5;
}

.filter-sidebar__list li {
    margin-bottom: 12px;
}

html[lang="ru_RU"] .filter-sidebar__block,
html[lang="ru_RU"] .filter-sidebar__block .checkbox-styled label {
    font-size: 14px;
}