/*------------------- modal close -----------*/
.product-modal__close {
    background: transparent;
    border: none;position: absolute;
    color: black;
    z-index: 100;
    opacity: 1;

    right: 20px;
    top: 20px;
}
.rtl-page .product-modal__close {
    left: 20px;
    right: auto;
}
.product-modal__close-icon {
    font-size: 16px;
}
.product-modal__close-icon:hover {
    color: var(--color-grey);
}
@media screen and (min-width: 768px) {
    .product-modal__close-icon {
        font-size: 16px;
    }
}