.img-teaser {
    position: relative;
    text-align: left;
}
.rtl-page .img-teaser {
    text-align: right;
}
.img-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    z-index: 1;
}
.img-teaser__content__hl {
    margin-bottom: 0;
    font-size: 20px;
    line-height:1;
  @media screen and (min-width: 1200px){
    font-size: 25px;
  }
}
.img-teaser--lg .img-teaser__content__hl {
  @media screen and (min-width: 768px){
    font-size: 28px;
  }
  @media screen and (min-width: 1200px){
      font-size: 40px;
  }
}
@media screen and (min-width: 1200px){
    .img-teaser--lg .img-teaser__content {
        padding: 40px 100px;
    }
}
.img-teaser__badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 2px 10px;
}

.img-teaser__img:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #000;
    opacity: .3;
    transition: opacity 350ms ease-in-out;
    transform: translateZ(0);
}
.img-teaser__img:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 2px solid var(--color-primary);
    z-index: 2;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}
.img-teaser:hover .img-teaser__img:after {
    opacity: 0;
}
.img-teaser:hover .img-teaser__img:before {
    opacity: 1;
}