.btn-dropdown {
    background: #fff;
    color: var(--color-dark);
    border: 1px solid var(--color-grey-light);
    width: 100%;
    text-align: left;
    position: relative;
    padding: 10px;
    height: 40px;
    line-height: 1;
}
.open .btn-dropdown,
.btn-dropdown:focus {
    border-bottom-color: #2f8acf;
}
.btn-dropdown:focus {
    outline: none;
}
.btn-dropdown__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 5px;
}
.open .btn-dropdown__icon {
    transform: translateY(-50%) rotate(180deg);
}
.btn-dropdown + .dropdown-menu {
    width: 100%;
    border: 1px solid var(--color-grey-light);
}

.dropdown-menu__content {
    padding: 10px;
}

.dropdown-menu__checkboxlist li + li {
    margin-top: 10px;
}