.cta-banner {
    color: white;
    padding: 25px 30px;
    line-height: 25px;
    background: var(--color-primary);
    background-image: url('/static/img/backgrounds/bg-overlay-red.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.cta-banner__icon {
    font-size: 50px;
}