.news-ticker {
    position: relative;
    color:#fff;
    background: url('/static/img/backgrounds/bg-overlay-grey.svg') #303030 50% 50% no-repeat;
    background-size: cover;
    padding: 10px 40px;
}
.news-ticker .slider__arrow {
    left:-24px;
}
.news-ticker .slider__arrow.slick-next {
    left:auto;
    right:-24px;
}
.news-ticker .slick-dots {
    left:-19999px;
    right: auto;
}
.news-ticker__item a {
    color:#fff;
    display: block;
    padding:10px 0 15px 0;
    @media screen and (min-width:768px) {
        padding:0;
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        position: relative;
        top: -3px;
    }
}

@media screen and (max-width:1199px) {
    .news-ticker__text,
    .news-ticker__date {
        display: block;
    }
}

@media screen and (min-width:1200px) {
    .news-ticker__text:before {
        position: relative;
        top:2px;
        margin:0 20px;
        font-family: iconfont;
        content:var(--icon-arrow-right);
    }
}