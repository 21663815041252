/*text helper*/
.text-underline, a.text-underline:hover { text-decoration: underline; }
.text-no-deco {
    text-decoration: none;
}

.no-margin.no-margin { margin: 0; }
.mb0 { margin-bottom: 0 }
.mb5 { margin-bottom: 5px; }
.mb10.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb25 { margin-bottom: 25px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }

@media screen and (max-width: 767px) {
    .mb-xs-10 { margin-bottom: 10px; }
    .mb-xs-20 { margin-bottom: 20px; }
    .mb-xs-30 { margin-bottom: 30px; }
}

.mt5 {margin-top: 5px;}
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.mt60 { margin-top: 60px; }

.ml5 {margin-left: 5px;}
.ml10 {margin-left: 10px;}
.ml20 {margin-left: 20px;}

.mr5 {margin-right: 5px;}
.mr10 {margin-right: 10px;}

.no-padding { padding: 0; }


/*RTL helper*/
.rtl-page .ml5 {
    margin-left: inherit;
    margin-right: 5px;
}
.rtl-page .ml10 {
    margin-left: inherit;
    margin-right: 10px;
}
.rtl-page .ml20 {
    margin-left: inherit;
    margin-right: 20px;
}

.rtl-page .mr5 {
    margin-right: inherit;
    margin-left: 5px;
}
.rtl-page .mr10 {
    margin-right: inherit;
    margin-left: 10px;
}


/*background colors*/
.bg-primary {background: var(--color-primary); color: #fff;}
.bg-secondary {background: var(--color-secondary); color: #fff;}
.bg-white { background: #fff; }
.bg-dark {
    color: #ffffff;
    background-color: var(--color-dark);
}
.bg-grey {
    background: #f1f1f1;
}
.bg-grey-middle {
    background: #CBCBCB;
}
.bg-green {
    background: #2A8634;
    color: #fff;
}

/*text colors*/
.text-primary,
.text-primary-hover:hover {color: var(--color-primary);}
.text-secondary { color: var(--color-secondary);}
.text-default { color: var(--color-dark); }
.text-white { color: #fff; }


/* make table scrollable on productdetailsites wf_1145145 */
#modelleDetail-product-details .table-responsive th,
#modelleDetail-product-details .table-responsive td{
    white-space: nowrap;
    padding: 12px 8px;
}
#modelleDetail-product-details .table-fullwidth th,
#modelleDetail-product-details .table-fullwidth td{
    width: 50%;
}
#modelleDetail-product-details .table-responsive th > img,
#modelleDetail-product-details .table-responsive td > img {
    display: block;
    margin:0 auto 5px auto;
}

#modelleDetail-product-details .table-responsive th {
    min-width: 120px;
    white-space: normal;
    vertical-align: middle;
}


/*helper*/
.pe-none {pointer-events: none;}
.isClickable {cursor: pointer;}
.d-ib {display: inline-block;}
.d-b {display: block;}
.d-flex {
    display: -ms-flexbox!important;
    display: -webkit-box!important;
    display: flex!important;
}

.justify-content-center {
    -ms-flex-pack: center!important;
    -webkit-box-pack: center!important;
    justify-content: center!important;
}

.align-content-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important;
}
.btn-default.text-default{
    border-color: #000000;
}

.btn-default.text-secondary{
    border-color: #ffe300;
}

@media screen and (min-width: 768px){
    .text-right-sm {
        text-align: right;
    }
    .rtl-page .text-right-sm {
        text-align: left;
    }
}

@media screen and (max-width: 767px){
    .text-center-xs {
        text-align: center;
    }
}
@media screen and (min-width: 1200px){
    .text-right-lg {
        text-align: right;
    }
    .text-left-lg {
        text-align: left;
    }
    .rtl-page .text-right-lg {
        text-align: left;
    }
    .rtl-page .text-left-lg {
        text-align: right;
    }
}

a.skiplink {
    position: fixed;
    left: -10000px;
    top: -10000px;
    z-index: 1000;
    font-size: 14px;
    padding: 3px;
}
a.skiplink:focus {
    left: 0;
    top: 0;
    outline: none;
    background-color: var(--color-primary);
    color: #fff;
}

.hide-focus:focus {
    outline: none;
}

.todo {
    background: orange;
    padding: 0 5px;
}

.export-info {
    color: #BFBFBF;
    margin-bottom: 10px;
}
.export-info__icon {
    font-size: 26px;
    vertical-align: -.2em;

    @media screen and (min-width: 768px) {
        font-size: 40px;
    }
}

.btn-no-styling {
    border: none;
    background: none;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.grecaptcha-badge {
    bottom: 65px!important;
    @media screen and (min-width: 768px) {
        bottom: 120px!important;
    }
}

.border-none {
    border: none!important;
}

.modal{
    z-index: 200;
}

.position-relative {
    position: relative;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.contact-person-filterbutton{
    padding: 6px 28px 6px;
}

.js-contact-person-reset{
    display: block;
    margin-bottom: 5px;
}