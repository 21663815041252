.product-teaser {
    border: 1px solid var(--color-grey-light);
    position: relative;
}
.product-teaser__link-container {
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 50px;
    height: 30px;

    @media screen and (min-width: 768px) {
        width: 80px;
        height: 45px;
    }
}
.rtl-page .product-teaser__link-container {
    left: 0;
    right: auto;
}
/* teaser head */
.product-teaser__head {
    position: relative;
}
.product-teaser__head__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @media screen and (min-width: 768px) {
        top: 5px;
    }
}
@media screen and (min-width: 768px) {
    .product-teaser__head__overlay.product-teaser__head__overlay--top {
        top: 0;
    }
}
.product-teaser__head__overlay--bottom {
    position: absolute;
    bottom: 5px;
    right: 0;
    left: 0;

    @media screen and (min-width: 768px) {
        bottom: 10px;
    }
}

.product-teaser__head__overlay--right.product-teaser__head__overlay--right {
    left: auto;
    right: 0;
}

.product-teaser__head__overlay.product-teaser__head__overlay--watchlist {
    left: auto;
    right: 10px;
}

.product-teaser__head--hide-border .embed-responsive {
    margin: -1px;
}

/* teaser features */
.product-teaser__features {
    display: flex;
    padding: 10px 6px 0;
}

@media screen and (max-width:767px){
    .product-teaser--grid .product-teaser__features {
        display: none;
    }
    .slick-slider .product-teaser--grid  .product-teaser__features {
        display: flex;
    }
}

.product-teaser__features__item {
    padding: 0 6px;
    line-height: 1.2;
    font-size: 11px;
    flex: 1;
}

.product-teaser__features__item + .product-teaser__features__item {
    border-left: 1px solid #DEE1E1;
}
.rtl-page .product-teaser__features__item + .product-teaser__features__item {
    border-left: none;
    border-right: 1px solid #DEE1E1;
}

.product-teaser__description {
    padding: 10px 10px 0;
    line-height: 1.1;
    font-size: 10px;
}

@media screen and (min-width:768px){
    .product-teaser__features {
        padding: 15px 12px 0;
        min-height: 65px;
    }
    .product-teaser__features__item {
        padding: 0 8px;
    }
    .product-teaser__description {
        padding: 15px 20px 0;
        font-size: 11px;
    }
}

/* teaser content */
.product-teaser__content {
    padding: 10px;
    position: relative;

    /* to align teaser bottom:
    add margin-top:auto and remove min-height on product-teaser__features

    margin-top: auto;*/
}

@media screen and (min-width: 768px){
    .product-teaser__content {
        padding: 15px 35px 15px 20px;
    }
    .rtl-page .product-teaser__content {
        padding: 15px 20px 15px 35px;
    }
    .product-teaser--vertical .product-teaser__content {
        padding: 20px 30px;
    }
}
.product-teaser__content__hl {
    font-size: 16px;
    line-height: calc(23/20);
    text-transform: none;
}
@media screen and (min-width: 768px){
    .product-teaser__content__hl {
        font-size: 20px;
    }
}
.product-teaser__content__subtitle,
.product-teaser--vertical .product-teaser__content__subtitle {
    font-size: 14px;
    line-height: calc(23/18);
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
        font-size: 18px;
        margin-bottom: 0;
    }
}

.product-teaser--vertical .product-teaser__content__text {
    font-size: 15px;
    margin-top: 14px;
}
.product-teaser .btn-icon--skew {
    bottom: -1px;
}

.product-teaser__content__category {
    font-size: 14px;
    line-height:1.2;
    margin-bottom: 5px;

    @media screen and (max-width: 767px) {
        font-size: 13px;
    }
}

.product-teaser__content__list {
    margin-bottom: 15px;
    margin-top: 15px;
}
.product-teaser__content__list li a{
    display: block;
    border-bottom:1px solid var(--color-grey-light);
    position: relative;
    padding-right: 25px;
}
.product-teaser__content__list li > a:after {
    content: var(--icon-arrow-right);
    font-family:iconfont;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 8px;
    transition: transform .2s ease-in-out;
}
.product-teaser__content__list li > a:hover:after {
    transform: translateY(-50%) translateX(5px);
}
.product-teaser__content__list li:first-child a{
    border-top:1px solid var(--color-grey-light);
}

/* product teaser badges */
.product-teaser-badge {
    padding: 1px 5px 3px;
    display: inline-block;
    line-height: 1.4;
    line-height: 1.4;
}
li + li .product-teaser-badge { margin-top: 5px; }

.product-teaser-badge--price {
    position: relative;
    font-size: 13px;
    line-height: calc(26/18);
    z-index: 1;
    @media screen and (min-width: 768px){
        margin-right: -9px;
        font-size: 18px;
    }
}
.product-teaser-badge__text {
    padding: 2px 4px 3px;
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    text-align: right;
    @media screen and (min-width: 768px){
        padding: 3px 10px 4px;
    }
}

@media screen and (min-width: 768px) {
    .product-teaser-badge--price:after {
        content: "";
        position: absolute;
        bottom: -5px;
        right: 0;
        width: 10px;
        height: 100%;
        background: #fff;
        z-index: -1;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,.5);
        transform: skewY(-40deg);
    }
}

/* badge colors */
.product-teaser-badge.bg-green {
    background: #009966;
}

.product-teaser-badge.bg-yellow {
    background: #ff9900;
    color: white;
}

/* special offer */
.product-teaser--special {
    border: 2px solid var(--color-primary);
}
.product-teaser--special .product-teaser__content {
    padding-top: 15px;
}
.product-teaser--special .product-teaser__content__category {
    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
}

.product-teaser--vertical .product-teaser__content__category {
@media screen and (min-width: 768px) {
    font-size: 14px;
}
}
.product-teaser--special .product-teaser__content__hl {
    @media screen and (min-width: 768px) {
        font-size: 26px !important;
    }
}

.product-teaser--vertical .product-teaser__content__hl {
    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
}
/*.product-teaser--special .product-teaser__content__subtitle {
    @media screen and (min-width: 768px) {
        font-size: 24px;
    }
}*/
.product-teaser--special .product-teaser__content__price,
.product-teaser--vertical .product-teaser__content__price {
    @media screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 1.2;
        margin-bottom: 5px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 36px;
    }
}
.product-teaser__content__old-price {
    line-height: 1;
    margin-top: 10px;
}
.product-teaser__content__text{
    font-size: 14px;
    line-height: calc(23/18);
    margin-top: 10px;

    @media screen and (min-width: 768px) {
        font-size: 18px;
        margin-top: 25px;
    }
}
.product-teaser__special-badge {
    font-size: 16px;
    padding: 0 10px 3px;
    letter-spacing: .5px;

    @media screen and (min-width: 768px) {
        font-size: 26px;
        padding: 0 20px 3px;
    }
}
.product-teaser--vertical .product-teaser__special-badge {
    @media screen and (min-width: 768px) {
        font-size: 20px;
        margin-right: -1px;
    }
}
@media screen and (min-width: 768px) {
    .product-teaser__special-badge--skewed {
        transform: skew(-34deg);
        width: 60%;
        margin-left: -14px;
    }
    .product-teaser__special-badge--skewed .product-teaser__special-badge__inner {
        transform: skew(34deg);
    }
    /* wrapper for the skewed badge */
    .product-teaser__special-badge-wrapper {
        overflow: hidden;
    }
}

.product-teaser--special .product-teaser__head__overlay--bottom {
    bottom: 10px;

    @media screen and (min-width: 768px) {
        bottom: 50px;
    }
}
.product-teaser--special .product-teaser-badge--price {
    @media screen and (min-width: 768px){
        font-size: 28px;
        line-height: 1;
        padding: 0 10px 4px;
        margin-right: 0;
        margin-left: -12px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 36px;
    }
}

@media screen and (min-width: 768px) {
    .product-teaser--special .product-teaser-badge--price:after {
        right: auto;
        left: 0;
        transform: rotate(40deg) skewX(40deg);
        width: 11px;
    }
}

.product-teaser__location {
    line-height: 2.2;
}

/* product teaser tooltip */
.product-teaser__content__info {
    line-height: 1;f
    top: 5px;
    position: relative;

    @media screen and (min-width: 768px) {
        position: absolute;
        right: 10px;
        top: 10px;
        left: 0;
        text-align: right;
    }
}
.product-teaser__content__info .tooltip-badge {
    margin-left: 0;
}

.price-text-info{
    font-size: 10px;
}