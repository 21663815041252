.short-news {
    position: relative;
    margin-bottom: 30px;
    /*min-height: 550px;*/

    @media screen and (min-width: 991px) {
        margin-bottom: 50px;
    }
}
.short-news__label {
    position: absolute;
    left:0;
    top:0;
    z-index:10;
    padding:10px 25px;
    text-transform: uppercase;
    line-height:1;
    font-family: var(--font-bold-family);
    display:inline-block;
}
.short-news__body {
    margin:15px 0;
}
.short-news__desc {
    margin:10px 0;
}