.page-info {
    overflow: hidden;
    position: relative;
}
.page-info__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    padding: 15px;
}
.rtl-page .page-info__close {
    left: 0;
    right: auto;
}
.page-info__close:hover {
    color: #fff;
}
.page-info__item {
    position: relative;
    overflow: hidden;
}
@media screen and (min-width: 768px){
    .page-info__item {
        transform: skew(-20deg);
        margin: 0 -20px;
    }
}
.page-info__item:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    right: -20px;
    background-image: url(/static/img/backgrounds/bg-overlay-red.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
@media screen and (min-width: 768px) {
    .page-info__item:before {
        transform: skew(20deg);
    }
}
.page-info__item--marine:before {
    background-image: url(/static/img/backgrounds/bg-overlay-blue.svg);
}
.page-info__item__content {
    position: relative;
    z-index: 1;
    padding: 6px 0;
}
@media screen and (min-width: 768px) {
    .page-info__item__content {
        transform: skew(20deg);
        padding: 5px 0 12px;
    }
}