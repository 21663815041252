.unit-toggle{
    height: 22px;
}
.rtl-page .unit-toggle {
    display: none;
}

@media screen and (max-width:767px){
    .unit-toggle__wrapper {
        display: flex!important;
        align-items: center;
        justify-content: space-between;
    }
}

.unit-toggle__switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 22px;
    background-color: var(--color-primary);
    border-radius: 16px;
    transition: all ease-in .2s;
}

.unit-toggle__text-left,
.unit-toggle__text-right {
    color: #000;
    font-size: 12px;
    position: relative;
    bottom: 8px;
}

.unit-toggle--metric-active .unit-toggle__text-left,
.unit-toggle:not(.unit-toggle--metric-active) .unit-toggle__text-right {
    opacity: 0.5;
}

.unit-toggle__toggle{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.unit-toggle__toggle::before {
    position: absolute;
    content:'';
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: #fff;
    border-radius: 50%;
    transition: all ease-in .2s;
}

.unit-toggle--metric-active .unit-toggle__toggle::before {
    transform: translateX(13px);
}

