.navbar-main__dropdown {
    padding-left: 15px;
    display: none;
    text-transform: none;
    font-family:var(--font-regular-family);
}
@media screen and (min-width: 768px){
    .navbar-main__dropdown {
        position: absolute;
        background: var(--color-dark);
        min-width: 100%;
        left: 0;
        padding: 20px 0;
        top:100%;
    }
    .rtl-page .navbar-main__dropdown {
        left:auto;
        right:0;
    }
    li.dropdown-align-left .navbar-main__dropdown {
        left:auto;
        right:0;
    }
    .navbar-main__dropdown--has-info {
        min-width: 300px;
    }
    .navbar-main li:hover .navbar-main__dropdown {
        display: block;
    }
}
li.is-open .navbar-main__dropdown {
    display: block;
}

.navbar-main__dropdown__bottom {
    margin-top: 20px;
}
@media screen and (min-width: 768px){
    .navbar-main__dropdown li,
    .navbar-main__dropdown__bottom {
        padding: 0 20px;
    }
}
.navbar-main__dropdown__bottom a>.icon {
    font-size: 12px;
    margin-right: 6px;
}
.navbar-main__dropdown li>a {
    position: relative;
    display: block;
}
@media screen and (min-width: 768px) {
    .navbar-main__dropdown li:first-child a{
        border-top:1px solid #484848;
    }
    .navbar-main__dropdown li>a {
        border-bottom:1px solid #484848;
        line-height: 1.2;
        padding: 7px 25px 6px 0;
    }
    .rtl-page .navbar-main__dropdown li>a {
        padding: 7px 0 6px 25px;
    }
}
.navbar-main__dropdown__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 8px;
    transition: transform 200ms ease-in-out;
}
.rtl-page .navbar-main__dropdown__icon {
    left: 10px;
    right: auto;
    display: inline-block;
    margin-top:-1px;
    transform: scaleX(-1) translateY(-50%);
}
.navbar-main__dropdown li>a:hover {
    color: var(--color-primary);
}
.navbar-main__dropdown a:hover .navbar-main__dropdown__icon {
    transform: translateY(-50%) translateX(200%);
}
.rtl-page .navbar-main__dropdown a:hover .navbar-main__dropdown__icon {
    transform: scaleX(-1) translateY(-50%) translateX(200%);
}

@media screen and (min-width: 768px) {
    .navbar-main__dropdown__info {
        position: absolute;
        top: 0;
        left: 100%;
        width: 300px;
        min-height: 100%;
        background: var(--color-dark);
        opacity: 0;
        transform: translateX(-100%);
        z-index: -1;
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    }
    .rtl-page .navbar-main__dropdown__info {
        left: auto;
        right: 100%;
        transform: translateX(100%);
    }
    .navbar-main__dropdown li:hover .navbar-main__dropdown__info,
    .navbar-main__dropdown a:focus ~ .navbar-main__dropdown__info {
        opacity: 1;
        transform: translateX(0);
    }
    .navbar-main__dropdown__info__img:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100px;
        background-image: linear-gradient(180deg, rgba(27, 27, 27, 0) 0%, #1b1b1b 100%);
    }
    .navbar-main__dropdown__info__content {
        font-size: 14px;
        line-height: calc(20/14);
        padding: 20px;
    }
    .navbar-main__dropdown .navbar-main__dropdown__info__content .h4>a {
        border-top: 0;
    }
}