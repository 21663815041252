.diagram-teaser {
    position: relative;
    text-align: center;
    overflow: hidden;
    display: block;
    background-color: #f1f1f1;
}
.diagram-teaser__body {
    padding:35px 25px 15px 25px;
}
.diagram-teaser__img {
    display: block;
    position: relative;
    overflow: hidden;
}
.diagram-teaser__img-img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}
.diagram-teaser__btns {
    margin:20px 0 0 0;
}
.diagram-teaser__btns .btn {
    margin:15px 0 0 0;
}