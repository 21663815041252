.social-list {
    font-size: 0;
    margin-left: -3px;
}
.social-list li {
    padding-left: 3px;
    padding-right: 2px;
}
.social-list__item{
    width: 30px;
    height: 30px;
    position: relative;
    display: block;
    background-size: 100%;
    background-image: url('/static/build/img/social-icons/social-sprite.png');
    transition: transform 200ms ease-in-out;
}
.social-list__item:hover {
    transform: translateY(-3px);
}

.social-list__item--blog { background-position: 0 0; background-size: 100%; }
.social-list__item--facebook { background-position: 0 12.485714%; background-size: 100%; }
.social-list__item--google { background-position: 0 25.071429%; background-size: 100%; }
.social-list__item--instagram { background-position: 0 37.657143%; background-size: 100%; }
.social-list__item--linkedin { background-position: 0 50.042857%; background-size: 100%; }
.social-list__item--twitter { background-position: 0 71.428571%; background-size: 100%; }
.social-list__item--xing { background-position: 0 75.114286%; background-size: 100%; }
.social-list__item--youtube { background-position: 0 87.5%; background-size: 100%; }
.social-list__item--naver { background-position: 0 100%; background-size: 100%; }