.hero-img {
    position: relative;
    overflow: hidden;
}
/* safari fix */
@media screen and (min-width: 768px) {
    #modelleDetail-hero-img .hero-img {
        overflow: initial;
    }
}
.hero-img__content {
    background: var(--color-primary);
    background-image: url('/static/img/backgrounds/bg-overlay-red.svg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: -30px 15px 30px;
    padding: 20px 15px;
    position: relative;
    z-index: 1;
    text-shadow: 0 0 4px rgba(65,65,65,.9);
}

.hero-img__anniversary-logo {
    position: absolute;
    right:50px;
    bottom:50px;
    display: block;
    width: 200px;
    height: auto;
    z-index: 10;

    @media screen and (max-width: 767px) {
        right:0;
        bottom:auto;
        top:-60px;
        width: auto;
        height:60px;
    }
}

@media screen and (max-width: 767px) {
    /* no box style */
    .hero-img__content--no-box {
        background: none;
        margin: 0;
        position: absolute;
        transform: translateY(0);
        bottom: 25px;
        left: 0;
        right: 0;
        text-align: center;
    }
    .hero-img--sm .hero-img__content--no-box {
        bottom: 0;
        padding: 10px 5px;
    }
}
@media screen and (min-width: 768px){
    .hero-img__content.hero-img__content--center {
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
    .hero-img__content.hero-img__content--top {
        bottom: auto;
        top: 150px;
    }
    .hero-img__content {
        background: transparent;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 105px;
        bottom: 70px;
        width:100%;
        max-width: 700px;
    }
    .rtl-page .hero-img__content {
        left: auto;
        right: 105px;
    }
    .hero-img__content.hero-img__content--container {
        width: 100%;
        left: 0;
        max-width: none;
        bottom: 60px;
    }

    .hero-img__content.hero-img__content--container-top {
        width: 100%;
        left: 0;
        max-width: none;
        top: 150px;
    }

    .hero-img__text-wrapper {
        max-width: 630px;
    }
}
@media screen and (min-width: 1200px){
    .hero-img__content {
        left: 120px;
        bottom: 120px;
        max-width: 890px;
    }
    .rtl-page .hero-img__content {
        left: auto;
        right: 120px;
    }
}

.hero-img__content__h1 {
    font-size: 26px;
}
@media screen and (min-width: 768px){
    .hero-img__content__h1 {
        font-size: 30px;
    }
}
@media screen and (min-width: 1200px){
    .hero-img__content__h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 767px) {
    .hero-img__content .text-secondary {
        color: #fff;
    }
}

/*animation*/
.hero-img-slider__item .embed-responsive-item {
    transform: scale(1.2);
    transform-origin: center;
    transition: transform 3s ease-in-out;
}
.hero-img-slider .slick-current.slick-active .embed-responsive-item {
    animation: zoom ease-in-out 1 3s;
    transform: scale(1);
}

.hero-img-slider .hero-video__item.slick-current.slick-active .embed-responsive-item{
    animation: none;
    transform: scale(1.1);
}


@keyframes zoom {
    from {transform: scale(1.2);}
    to {transform: scale(1);}
}

.hero-img__content__logo {
    width: 100px;
    height: auto;
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
        width: 122px;
        margin-bottom: 10px;
    }
}

/* small hero img */
.hero-img--sm .hero-img__content__h1 {
    font-size: 18px;
    margin-bottom: 0;

    @media screen and (min-width: 768px){
        font-size: 30px;
    }
    @media screen and (min-width: 1200px){
        font-size: 50px;
    }
}