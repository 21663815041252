.slider {
    clear: both;
}

.slider--hide-dots .slick-dots {
    display: none !important;
}

.slider--hide-arrows .slider__arrow {
    display: none !important;
}

.slider__arrow {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 26px;
    color: #fff;
    z-index: 2;

    @media screen and (min-width: 786px){
        left: 44px;
    }
}
.rtl-page .slider__arrow {
    left: auto;
    right: 10px;
    transform: scaleX(-1) translateY(-50%);

    @media screen and (min-width: 786px){
        left: auto;
        right: 44px;
    }
}
.slider__arrow.slick-next {
    left: auto;
    right: 10px;

    @media screen and (min-width: 768px) {
        left: auto;
        right: 30px;
    }

    @media screen and (min-width: 1200px) {
        right: 44px;
        left: auto;
    }
}
.rtl-page .slider__arrow.slick-next {
    left: 10px;
    right: auto;

    @media screen and (min-width: 768px) {
        left: 30px;
        right: auto;
    }

    @media screen and (min-width: 1200px) {
        left: 44px;
        right: auto;
    }
}

.slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
}
.adaptable-slider .slick-dots,
.slider-static-dots .slick-dots {
    position: static;
}
.teaser-slider-default .slick-dots,
.teaser-slider-xs .slick-dots {
    position: relative;
    left:auto;
    right:auto;
    bottom:auto;
    top:auto;
    margin-top:20px;
}
@media (max-width: 767px) {
    .adaptable-slider .slick-dots,
    .slider-static-dots .slick-dots {
        margin-bottom: 20px;
        margin-top: 10px;
    }
}
@media (min-width: 768px) {
    .adaptable-slider .slick-dots,
    .slider-static-dots .slick-dots {
        margin-top: 30px;
    }
}
@media screen and (max-width: 767px) {
    .hero-img-slider .slick-dots {
        bottom: auto;
        top:0;
        margin-top: 75%;
    }
    .hero-img-slider--left-dots .slick-dots {
        left:10px;
        right:auto;
    }
}
.slick-dots>li {
    float: none;
    display: inline-block;
}
.slick-dots__btn {
    position: relative;
    margin: 0 5px;
    padding: 0;
    color: #fff;
    background: none;
    border: 0;
    width: 30px;
    overflow: hidden;
}
.slick-dots__btn__text {
    font-size: 14px;
    margin-bottom: 5px;
    transform: translateY(100%);
    opacity: 0;
    display: block;
    position: relative;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}
.slick-active .slick-dots__btn__text {
    transform: translateY(0);
    opacity: 1;
}
.slick-dots__btn:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255, .7);
    height: 3px;
    transition: transform 200ms ease-in-out, background 200ms ease-in-out;
    transform-origin: bottom center;
    transform: translateZ(0) scaleY(1);
}
.slick-active>.slick-dots__btn:after {
    background: var(--color-primary);
    transform: translateZ(0) scaleY(2);
}
.slick-dots__btn:focus {
    outline: none;
}
.slick-dots__btn:focus:after {
    background: var(--color-primary);
}
/*color secondary slick dots*/
.slick-dots__btn--secondary:focus:after,
.slick-active>.slick-dots__btn--secondary:after {
    background: var(--color-secondary);
}

/*slider arrows outside*/
.slider--arrows-outside .slider__arrow.slick-prev {
    left: 0;

    @media screen and (min-width: 1400px) {
        left: -30px;
    }

    @media screen and (min-width: 1500px) {
        left: -60px;
    }
}
.slider--arrows-outside .slider__arrow.slick-next {
    right: 0;

    @media screen and (min-width: 1400px) {
        right: -30px;
    }

    @media screen and (min-width: 1500px) {
        right: -60px;
    }
}

/*slider on white bg*/
.slider-white-bg .slider__arrow {
    color: var(--color-grey-light);
    transition: color 200ms ease-in-out;
}
.slider-white-bg .slider__arrow:hover {
    color: var(--color-primary);
}
.slider-white-bg .slick-dots__btn:after {
    background: var(--color-grey-light);
}
.slider-white-bg .slick-dots__btn .slick-dots__btn__text {
    color: var(--color-dark);
}
.slider-white-bg .slick-active>.slick-dots__btn:after{
    background: var(--color-primary);
}

/*adaptable-slider arrows*/
.adaptable-slider .slider__arrow {
    background: #b2b2b2;
    right:0 !important;
    color:#fff !important;
}
.adaptable-slider .slider__arrow .icon {
    position: relative;
    top:3px;
}
.adaptable-slider .slider__arrow.slick-prev {
    left:0 !important;
    right:auto !important;
}

/* slider with thumbs */
.slider__thumbs {
    margin: 30px -15px 0;
}
.slider__thumbs__item{
    padding: 0 15px;
}
.slider__thumbs__item.slick-current figure{
    border: 2px solid var(--color-primary);
}
.slider__thumbs .slider__arrow {
    left: 20px;
}
.slider__thumbs .slick-next.slider__arrow {
    right: 20px;
}

@media screen and (max-width: 767px) {
    .slider--full-width-xs {
        margin: 0 -15px;
    }
}