@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1737012643857/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1737012643857/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1737012643857/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.icon-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.icon-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
    filter: none;
}

.icon-all-products:before { content: "\EA01" }
    .icon-arrow-down:before { content: "\EA02" }
    .icon-arrow-left:before { content: "\EA03" }
    .icon-arrow-right:before { content: "\EA04" }
    .icon-arrow-solid-down:before { content: "\EA05" }
    .icon-arrow-up:before { content: "\EA06" }
    .icon-attention:before { content: "\EA07" }
    .icon-calendar:before { content: "\EA08" }
    .icon-challenges:before { content: "\EA09" }
    .icon-checked:before { content: "\EA0A" }
    .icon-close:before { content: "\EA0B" }
    .icon-current_location:before { content: "\EA0C" }
    .icon-docx:before { content: "\EA0D" }
    .icon-download:before { content: "\EA0E" }
    .icon-elements:before { content: "\EA0F" }
    .icon-email:before { content: "\EA10" }
    .icon-event:before { content: "\EA11" }
    .icon-eye:before { content: "\EA12" }
    .icon-filter:before { content: "\EA13" }
    .icon-gps-fixed:before { content: "\EA14" }
    .icon-grid-view:before { content: "\EA15" }
    .icon-handle-bar-lines:before { content: "\EA16" }
    .icon-hat:before { content: "\EA17" }
    .icon-home:before { content: "\EA18" }
    .icon-info-circle:before { content: "\EA19" }
    .icon-info:before { content: "\EA1A" }
    .icon-journal:before { content: "\EA1B" }
    .icon-link-arrow-external-rtl:before { content: "\EA1C" }
    .icon-link-arrow-external:before { content: "\EA1D" }
    .icon-link-arrow-internal-rtl:before { content: "\EA1E" }
    .icon-link-arrow-internal:before { content: "\EA1F" }
    .icon-list-view:before { content: "\EA20" }
    .icon-location-pin:before { content: "\EA21" }
    .icon-material-phone-round:before { content: "\EA22" }
    .icon-material-phone:before { content: "\EA23" }
    .icon-menu:before { content: "\EA24" }
    .icon-minus:before { content: "\EA25" }
    .icon-navigation:before { content: "\EA26" }
    .icon-partner-success:before { content: "\EA27" }
    .icon-pdf:before { content: "\EA28" }
    .icon-phone:before { content: "\EA29" }
    .icon-play:before { content: "\EA2A" }
    .icon-plus:before { content: "\EA2B" }
    .icon-podcast:before { content: "\EA2C" }
    .icon-portraits:before { content: "\EA2D" }
    .icon-search:before { content: "\EA2E" }
    .icon-send:before { content: "\EA2F" }
    .icon-star-filled:before { content: "\EA30" }
    .icon-star-outline:before { content: "\EA31" }
    .icon-story:before { content: "\EA32" }
    .icon-video:before { content: "\EA33" }
    .icon-world-tour:before { content: "\EA34" }
    

:root {
--icon-all-products: "\EA01";
    --icon-arrow-down: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-arrow-solid-down: "\EA05";
    --icon-arrow-up: "\EA06";
    --icon-attention: "\EA07";
    --icon-calendar: "\EA08";
    --icon-challenges: "\EA09";
    --icon-checked: "\EA0A";
    --icon-close: "\EA0B";
    --icon-current_location: "\EA0C";
    --icon-docx: "\EA0D";
    --icon-download: "\EA0E";
    --icon-elements: "\EA0F";
    --icon-email: "\EA10";
    --icon-event: "\EA11";
    --icon-eye: "\EA12";
    --icon-filter: "\EA13";
    --icon-gps-fixed: "\EA14";
    --icon-grid-view: "\EA15";
    --icon-handle-bar-lines: "\EA16";
    --icon-hat: "\EA17";
    --icon-home: "\EA18";
    --icon-info-circle: "\EA19";
    --icon-info: "\EA1A";
    --icon-journal: "\EA1B";
    --icon-link-arrow-external-rtl: "\EA1C";
    --icon-link-arrow-external: "\EA1D";
    --icon-link-arrow-internal-rtl: "\EA1E";
    --icon-link-arrow-internal: "\EA1F";
    --icon-list-view: "\EA20";
    --icon-location-pin: "\EA21";
    --icon-material-phone-round: "\EA22";
    --icon-material-phone: "\EA23";
    --icon-menu: "\EA24";
    --icon-minus: "\EA25";
    --icon-navigation: "\EA26";
    --icon-partner-success: "\EA27";
    --icon-pdf: "\EA28";
    --icon-phone: "\EA29";
    --icon-play: "\EA2A";
    --icon-plus: "\EA2B";
    --icon-podcast: "\EA2C";
    --icon-portraits: "\EA2D";
    --icon-search: "\EA2E";
    --icon-send: "\EA2F";
    --icon-star-filled: "\EA30";
    --icon-star-outline: "\EA31";
    --icon-story: "\EA32";
    --icon-video: "\EA33";
    --icon-world-tour: "\EA34";
    
}