.external-link:after,
.internal-link:after {
    font-family: iconfont;
    color: inherit;
    font-size: 10px;
    margin-left: 10px;
}
.external-link:after {
    content: var(--icon-link-arrow-external);
    vertical-align: 0.6em;
}

.internal-link:after {
    content: var(--icon-link-arrow-internal);
    font-family: iconfont;
}

@media screen and (min-width: 768px){
    .h3.external-link:after {
        vertical-align: 1.2em;
    }
}


/* RTL */
.rtl-page .external-link:after,
.rtl-page .internal-link:after {
    display: none;
}
.rtl-page .external-link:before,
.rtl-page .internal-link:before {
    position: relative;
    font-family: iconfont;
    color: inherit;
    font-size: 10px;
    margin-right: 10px;
    transform: scaleX(-1);
}
.rtl-page .external-link:before {
    content: var(--icon-link-arrow-external-rtl);
    vertical-align: 0.6em;
}
.rtl-page .internal-link:before {
    content: var(--icon-link-arrow-internal-rtl);
    font-family: iconfont;
}

@media screen and (min-width: 768px){
    .rtl-page .h3.external-link:before {
        vertical-align: 1.2em;
    }
}