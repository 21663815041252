/* colors */
.contacts-body--grey,
.contacts-header--grey {
    background-color: #f1f1f1;
}

/* spacings */
.contacts-header--small {
    padding:30px 0 12px 0;

    @media screen and (max-width:767px){
        padding:18px 0 0 0;
    }
}
.contacts-header--default {
    padding:50px 0 32px 0;

    @media screen and (max-width:767px){
        padding:38px 0 20px 0;
    }
}
.contacts-header--large {
    padding:80px 0 62px 0;

    @media screen and (max-width:767px){
        padding:45px 0 27px 0;
    }
}


.contacts-body--small {
    padding:30px 0 0 0;

    @media screen and (max-width:767px){
        padding:18px 0 0 0;
    }
}
.contacts-body--default {
    padding:50px 0 20px 0;

    @media screen and (max-width:767px){
        padding:38px 0 20px 0;
    }
}
.contacts-body--large {
    padding:80px 0 50px 0;

    @media screen and (max-width:767px){
        padding:45px 0 5px 0;
    }
}


/* fix flex bug */
@media screen and (min-width:768px){
    .contacts-results>.row>.row--same-height__item {
        flex: 0 0 auto;
    }
}