html.noScroll, html.noScroll body {
    overflow:initial !important;
    height: auto !important;
}

body {
    position: relative;
    word-break: break-word;
}
body.ov-hidden {
    overflow: hidden;
    width: 100vw;
    height: 100dvh;
}
.page-wrapper {
    background: #ffffff;
    position: relative;
}
@media (min-width: 768px) {
    .paldrive .page-wrapper {
        min-height: calc(100vh - 258px);
    }
}
.container {
    max-width: 1320px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.container-outer {
    max-width: 1920px;
    margin: 0 auto;
}
/*.container-narrow {*/
    /*max-width: 968px;*/
    /*margin: 0 auto;*/
/*}*/

@media screen and (max-width: 991px) and (min-width: 768px) {
    body {
        width: 982px;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
    html {
        overflow-x: hidden;
    }

    .row {
        margin-left:-5px;
        margin-right:-5px;
    }
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-left: 5px;
        padding-right: 5px;
    }

    /*.container--padding-0-xs {*/
        /*margin: 0 -15px;*/
    /*}*/
}


@media screen and (min-width: 1919px) {
    body {
        background: url(/static/img/backgrounds/bg-overlay-grey.svg) 50% 50% no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }
}