.highlight-slide {
    position: relative;

    @media screen and (min-width: 768px) {
        overflow: hidden;
    }
}
@media screen and (min-width: 768px){
    .highlight-slide__img {
        width: 60%;
    }
}
.highlight-slide__content {
    background-image: url(/static/img/backgrounds/bg-overlay-red.svg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: -30px 15px 30px;
    padding: 20px 15px 80px;
}

@media screen and (min-width: 768px){
    .highlight-slide__content {
        background-image: url('/static/img/backgrounds/skew-bg-red.png');
    }
    .rtl-page .highlight-slide__content {
        background-position: right top;
        background-image: url('/static/img/backgrounds/skew-bg-red-left.png');
    }
}
.highlight-slide__content--noslider {
    padding-bottom:30px;
}
.highlight-slide__content--grey {
    background-image: url(/static/img/backgrounds/bg-overlay-grey.svg);
}
@media screen and (min-width: 768px){
    .highlight-slide__content--grey {
        background-image: url('/static/img/backgrounds/skew-bg-grey.png');
    }
    .rtl-page .highlight-slide__content--grey {
        background-position: right top;
        background-image: url('/static/img/backgrounds/skew-bg-grey-left.png');
    }
}

.highlight-slide--img-right .highlight-slide__content.highlight-slide__content--lightgrey {
    background-color:#f1f1f1;
    background-image:none;
    @media screen and (min-width: 768px){
        background-color:transparent;
        background-image: url('/static/img/backgrounds/skew-bg-lightgrey-left.png');
    }
}
@media screen and (min-width: 768px){
    .highlight-slide__content {
        margin: 0;
        padding: 0;
        background-position: left bottom;
        position: absolute;
        width: 57.688%;
        right: 0;
        top: -1px;
        bottom: -1px;
    }
    .rtl-page .highlight-slide__content {
        left: 0;
        right: auto;
    }
    .highlight-slide__content__text {
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right:0;
        padding: 0 5% 0 30%;
    }
    .rtl-page .highlight-slide__content__text {
        padding: 0 30% 0 5%;
    }
    .highlight-slide__content .slick-slide {
        pointer-events: none;
    }
    .highlight-slide__content .slick-slide.slick-current {
        pointer-events: initial;
    }
}
.highlight-slide__content .slick-track {
    @media screen and (min-width: 768px){
        display: flex;
        align-items: center;
    }
}

@media screen and (min-width: 768px){
    .highlight-slide__content .slick-dots {
        text-align: left;
        padding: 0 5% 0 30%;
    }
    .highlight-slide__content .slick-prev {
        left: 24%;
    }
    .highlight-slide__content .slick-next.slick-next {
        right: 10px;
    }
    .rtl-page .highlight-slide__content .slick-prev {
        left:auto;
        right: 24%;
        transform: scaleX(-1);
    }
    .rtl-page .highlight-slide__content .slick-next.slick-next {
        right:auto;
        left: 10px;
        transform: scaleX(-1);
    }
}

.highlight-slide__content__hl {
    margin-bottom: 15px;
    font-size: 25px;

    @media screen and (min-width: 768px) {
        margin-top: 20px;
    }
}
@media screen and (min-width: 992px) {
    .highlight-slide__content__hl {
        font-size: 35px;
    }
}
@media screen and (min-width: 1300px) {
    .highlight-slide__content__hl {
        margin-bottom: 25px;
        font-size: 40px;
    }
}

/*has image right*/
@media screen and (min-width: 768px){
    .highlight-slide--img-right .highlight-slide__img {
        margin-left: 40%;
    }
    .highlight-slide--img-right .highlight-slide__content {
        background-image: url('/static/img/backgrounds/skew-bg-red-left.png');
        left: 0;
        right: auto;
    }
    .highlight-slide--img-right .highlight-slide__content--grey {
        background-image: url('/static/img/backgrounds/skew-bg-grey-left.png');
    }
    .highlight-slide--img-right .highlight-slide__content--lightgrey {
        background-image: url('/static/img/backgrounds/skew-bg-grey-left.png');
    }
    .highlight-slide--img-right .highlight-slide__content__text,
    .highlight-slide--img-right .highlight-slide__content .slick-dots {
        padding: 0 30% 0 5%;
    }
    .highlight-slide--img-right .slick-prev {
        left: 5px;
    }
    .highlight-slide--img-right .slider__arrow.slick-next {
        right: 24%;
    }
}


/* quote */
.highlight-slide__quote {
    position: relative;
}
.highlight-slide__quote-q {
    position: absolute;
    left:-90px;
    bottom:0;
    color:#fff;
    font-size:175px;
    line-height: 1;
}
.rtl-page .highlight-slide__quote-q {
    left:auto;
    right:-90px;
}

.quote-top {
    top: -150px;
    bottom: auto;
}

.quote-bottom {
    left: auto;
    right: 0;
    bottom: -50px;
}

@media screen and (min-width: 768px){
    .highlight-slide__quote--large {
        font-size: 20px;
    }
}

@media screen and (max-width: 767px) {
    .highlight-slide__quote-q {
        display: none;
    }
}

.pimcore_area_ag-quote .highlight-slide__content__text > div + div,
.pimcore_area_ag-jobteaser .highlight-slide__content__text > div + div {
    display: block;
}

.slick-body {
    padding: 60px 0;
}