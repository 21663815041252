.category-teaser__hl {
    font-size: 16px;
    line-height:calc(24/16);
    margin-top: 5px;
    margin-bottom: 0;
    text-transform: none;
}
@media screen and (min-width: 768px){
    .category-teaser__hl {
        font-size: 20px;
        line-height:calc(33/20);
        margin-top: 10px;
    }
}