.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}.custom-radio:after {
     clear: both;
 }
.custom-radio__input[type=radio] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    overflow: hidden;
    background: #ffffff;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0;
    border-radius: 50%;
    border: 1px solid #767676;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
}
.rtl-page .custom-radio__box {
    left: auto;
    right: 0;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--color-dark);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-dark);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio label {
    font-family:var(--font-regular-family);
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    margin: 0;
    line-height: 1.2;
    padding-left: 22px;
    position: relative;
}
.rtl-page .custom-radio label {
    padding-right: 22px;
    padding-left: 0;
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}