.country-dropdown {
    position: relative;
}
@media screen and (min-width: 768px){
    .country-dropdown {
        width: 260px;
        margin-bottom:50px;
    }
    .country-dropdown__hl{
        margin-bottom: 35px;
    }
}
.country-dropdown__hl{
    margin-bottom: 30px;
}

.country-dropdown li:first-child > a,
li:first-child > .country-dropdown__sub__item {
    border-top:1px solid var(--color-grey-light);
}
.country-dropdown__sub {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width:100%;
    background: #fff;
    transform: translateX(110%);
    transition: transform 250ms ease-in-out;
    z-index: 1;
}
@media screen and (min-width: 768px){
    .country-dropdown__sub {
        left: 100%;
        width:calc(100% + 20px);
        padding-left: 20px;
        opacity: 0;
        pointer-events: none;
        transform: translateX(0);
        transition: opacity 200ms ease-in-out;
    }
    .rtl-page .country-dropdown__sub {
        left: auto;
        right: 100%;
        padding-left: 0;
        padding-right: 20px;
    }
    .country-dropdown li:hover .country-dropdown__sub,
    .country-dropdown li.is-active .country-dropdown__sub {
        pointer-events: inherit;
        opacity: 1;
    }
}

.country-dropdown li.is-active .country-dropdown__sub {
    transform: translateX(0);
}

.country-dropdown li>a,
.country-dropdown__sub__item {
    border-bottom:1px solid var(--color-grey-light);
    display: block;
    position: relative;
    background: #fff;
    transition: background 200ms ease-in-out;
    padding: 6px 10px;
    font-size: 16px;
}
@media screen and (min-width: 768px) {
    .country-dropdown li>a,
    .country-dropdown__sub__item {
        padding: 3px 10px 6px;
    }
}

.rtl-page .country-dropdown li>a,
.rtl-page .country-dropdown__sub__item {
    font-size: 14px;
}

.country-dropdown__sub__item {
    padding-right: 0;
}
.country-dropdown li:hover > .country-dropdown__main,
.country-dropdown li.is-active > .country-dropdown__main {
    background: #f1f1f1;
    color: var(--color-primary);
}
.country-dropdown__main__arrow {
    position: absolute;
    right: 10px;
    font-size: 10px;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-5px);
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}
.country-dropdown li:hover .country-dropdown__main__arrow,
.country-dropdown li.is-active .country-dropdown__main__arrow {
    transform: translateY(-50%) translateX(0);
    opacity: 1;
}
.rtl-page .country-dropdown__main__arrow {
    left: 10px;
    right: auto;
    transform: translateY(-50%) translateX(-5px) scaleX(-1);
}
.rtl-page .country-dropdown li:hover .country-dropdown__main__arrow,
.rtl-page .country-dropdown li.is-active .country-dropdown__main__arrow {
    transform: translateY(-50%) translateX(0) scaleX(-1);
}

.country-dropdown li>a:hover,
.country-dropdown__sub__item:hover .country-dropdown__sub__item__title {
    color: var(--color-primary);
}
.country-dropdown__sub__item a {
    position: relative;
}
.country-dropdown__sub__item a + a {
    margin-left: 12px;
}
.country-dropdown__sub__item a + a:before {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 3px;
    left: -9px;
    width: 1px;
    background: #878787;
}

.country-dropdown__sub__item--back {
    background: #f1f1f1;
    padding: 7px 40px;
    width: 100%;
    border: 0;
    position: relative;
    border-bottom: 1px solid var(--color-grey-light);
    border-top: 1px solid var(--color-grey-light);
}
.country-dropdown__sub__item--back > .icon {
    font-size: 10px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

/*flag*/
.sprite {
    display: inline-block;
    width: 24px;
    height: 18px;
    vertical-align: -.2em;
    margin-right: 10px;
    max-width: 100%;
    background-size: 100%;
    background-image: url('/static/img/flags/flag-sprite-2023.png');
}
.sprite-other,
.sprite-other-dark {
    height: 24px;
    vertical-align: -0.3em;
}
.rtl-page .sprite {
    margin-right: 0;
    margin-left: 10px;
}
.rtl-page .sprite-other,
.rtl-page .sprite-other-dark {
    vertical-align: -0.6em;
}

.sprite-at { background-position: 0 0; background-size: 100%; }
.sprite-au { background-position: 0 -17px; background-size: 100%; }
.sprite-br { background-position: 0 -36px; background-size: 100%; }
.sprite-ca { background-position: 0 -55px; background-size: 100%; }
.sprite-de { background-position: 0 -74px; background-size: 100%; }
.sprite-es { background-position: 0 -93px; background-size: 100%; }
.sprite-eu { background-position: 0 -112px; background-size: 100%; }
.sprite-fr { background-position: 0 -131px; background-size: 100%; }
.sprite-gb { background-position: 0 -148px; background-size: 100%; }
.sprite-in { background-position: 0 -168px; background-size: 100%; }
.sprite-jp { background-position: 0 -188px; background-size: 100%; }
.sprite-pt { background-position: 0 -205px; background-size: 100%; }
.sprite-ru { background-position: 0 -224px; background-size: 100%; }
.sprite-us { background-position: 0 -243px; background-size: 100%; }
.sprite-other-dark { background-position: 0 -394px; background-size: 150%; }
.sprite-other { background-position: 0 -419px; background-size: 150%; }
.sprite-kr { background-position: 0 -297px; background-size: 100%; }
.sprite-mx { background-position: 0 -315px; background-size: 100%; }
.sprite-zh { background-position: 0 -334px; background-size: 100%; }
.sprite-cn { background-position: 0 -334px; background-size: 100%; }
.sprite-vn { background-position: 0 -524px; background-size: 100%; }
.sprite-dk { background-position: 0 -353px; background-size: 100%; }
.sprite-it { background-position: 0 -372px; background-size: 100%; }
.sprite-no { background-position: 0 -391px; background-size: 100%;}
.sprite-se { background-position: 0 -410px; background-size: 100%; }
.sprite-sk { background-position: 0 -429px; background-size: 100%;}
.sprite-tr { background-position: 0 -447px; background-size: 100%; }
.sprite-ar { background-position: 0 -465px; background-size: 100%; }
.sprite-za { background-position: 0 -485px; background-size: 100%; }
.sprite-by { background-position: 0 -504px; background-size: 100%; }
.sprite-vi { background-position: 0 -524px; background-size: 100%; }
.sprite-nl { background-position: 0 -545px; background-size: 100%; }
.sprite-sa { background-position: 0 -565px; background-size: 100%; }
.sprite-fi { background-position: 0 -582px; background-size: 100%; }

.modal__close {
    position: absolute;
    right: 10px;
    top: 6px;
    background: transparent;
    border: 0;
    padding: 5px;
    font-size: 14px;
    z-index: 1;
    color: #767676;
}
.rtl-page .modal__close {
    left: 10px;
    right: auto;
}
@media screen and (max-width: 767px){
    .modal-open {
        overflow: hidden;
    }
    .modal-full-xs {
        height: 100vh;
        z-index: 1500;
    }
    .modal-full-xs .modal-content {
        border-radius: 0;
        border: 0;
        box-shadow: none;
        height: 100vh;
    }
}

.country-toggle{
    background: var(--color-dark);
    border: 1px solid #757575;
    width: 100%;
    padding: 8px 12px;
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
}
.rtl-page .country-toggle {
    text-align: right;
}

.dropdown .country-toggle {
    margin-bottom: 0;
}