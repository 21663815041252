.dealer-search-item {
  line-height: calc(20/16);
    position: relative;

  @media screen and (min-width: 768px) {
    border-top: 1px solid var(--color-grey-light);
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.dealer-search-item--highlighted:after {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    bottom: 0;
    width: 8px;
    background: var(--color-primary);
}

@media screen and (min-width: 768px) {
  .dealer-search-item:last-child {
    border-bottom: 1px solid var(--color-grey-light);
  }
}

.dealer-search-item__title {
  font-family: var(--font-bold-family);
  font-size: 17px;
  @media screen and (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.dealer-search-item__details {
  font-size: 14px;

  @media screen and (min-width: 768px) {
    border-left: 1px solid var(--color-grey-light);
  }
}

.dealer-search-item__directions-link .icon-navigation {
  font-size: 12px;
}

.dealer-search-item__info-block + .dealer-search-item__info-block {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .dealer-search-item > .panel {
    margin-bottom: 5px;
  }

  .dealer-search-item__info-block {
    margin-top: 10px;
  }

  .dealer-search-item__contact-block {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .dealer-search-item__image {
    margin-bottom: 10px;
  }

  .dealerlist__inner {
    margin-top: 5px;
    padding-top: 15px;
    border-top: 1px solid var(--color-grey-light);
  }
}

.dealer-search-item__info-block .email {
    color: var(--color-primary);
}

.showgeoerror,
.showplzerror{
    display: none;
    font-size: 16px;
    float: right;
}

.js-show-detaillist,
.js-gotostep{
    display: none;
}


.js-get-location{
    cursor: pointer;
}

.dealer-search-item img{
    margin: 0 auto;
}