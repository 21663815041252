.breadcrumb .breadcrumb__backlink {
  display: block;
  position: relative;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px){
    .breadcrumb .breadcrumb__backlink {
        margin-bottom: 0;
        padding-right: 30px;
        display: inline-block;
    }
    .rtl-page .breadcrumb .breadcrumb__backlink {
        padding-right: 0;
        padding-left: 30px;
    }
    .breadcrumb__backlink:after {
        content: '';
        position: absolute;
        top: -10px;
        bottom: -10px;
        right: 13px;
        width: 1px;
        background: var(--color-grey-light);
    }
    .rtl-page .breadcrumb__backlink:after {
        right: auto;
        left: 13px;
    }
    .rtl-page .breadcrumb__backlink .icon:before {
        content:var(--icon-arrow-right);
    }
}
.breadcrumb__backlink > a{
  color: var(--color-dark);
}
.breadcrumb__backlink > a >.icon {
  font-size: 10px;
  margin-right: 6px;
}
.rtl-page .breadcrumb__backlink > a >.icon {
  margin-right: 0;
  margin-left: 6px;
}
.breadcrumb .breadcrumb__backlink + li:before {
  content: none;
}

/* small breadcrumb */
.breadcrumb--sm {
  @media screen and (min-width: 768px) {
    margin-top: 10px;
    border-bottom: 0;
  }
}

.breadcrumb.breadcrumb--below-nav {
  margin-top: 70px;
  @media screen and (min-width: 768px) {
    margin-top: 110px;
  }
}

.breadcrumb>li+li:after {display: none;}

.rtl-page .breadcrumb>li+li:before {
    display: none;
}
.rtl-page .breadcrumb>li+li:after {
    display: inline;
    content:var(--icon-arrow-left);
}
.rtl-page .breadcrumb>li.breadcrumb__backlink+li:after {
    display: none;
}