body {
    font-size: 14px;
    color: var(--color-dark);
    line-height: calc(24 / 14);
    font-family: var(--font-regular-family);
    font-weight: 400;
}
@media screen and (min-width: 992px){
    body {
        font-size: 16px;
        line-height: calc(30 / 16);
    }
}

.font-bold, strong, b {
    font-family: var(--font-bold-family);
}
.font-light {
    font-family:var(--font-light-family);
    font-weight: 400;
}
.font-regular {
    font-family:var(--font-regular-family);
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 20px;
    color: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family:var(--font-palfinger-family);
}
h1, .h1 {
    font-size: 28px;
    font-size: calc(50px - 22 * ((1600px - 100vw) / 1280));
    line-height: calc(34/30);
    margin-bottom: 10px;
}
h2, .h2 {
    font-size: 25px;
    line-height: 1;
}
h3, .h3 {
    font-size: 17px;
    line-height: calc(24/17);
}
h4, .h4 {
    font-size: 14px;
    line-height: calc(20/14);
    margin-bottom: 10px;
    font-family:var(--font-bold-family);
}
.intro {
    font-size: 20px;
    line-height:calc(28/20);
}
.intro-sm {
    font-size: 16px;
    line-height:calc(24/16);
}
@media screen and (min-width: 768px) {
    h1, .h1 {
        /*font-size: 30px;*/
    }
}
@media screen and (min-width: 992px){
    h1, .h1 {
        /*font-size: 50px;*/
        line-height: calc(62/50);
    }
    h2, .h2 {
        font-size: 40px;
    }
    h3, .h3 {
        font-size: 26px;
        line-height: 1;
    }
    h4, .h4 {
        font-size: 16px;
        line-height: calc(24/16);
        margin-bottom: 20px;
    }
    .intro {
        font-size: 30px;
        line-height:calc(44/30);
    }
    .intro-sm {
        font-size: 20px;
        line-height:calc(33/20);
    }
}

@media screen and (min-width: 1600px) {
    h1, .h1 {
        font-size: 50px;
    }
}

.fz16 { font-size: 16px; }


/* reset SAP Styles from Jobs Import */
.reset-sap-styles table {
    width:100% !important;
    font-size:inherit !important;
    font-family: inherit !important;
}
.reset-sap-styles .table-responsive,
.reset-sap-styles table td,
.reset-sap-styles table th,
.reset-sap-styles table span {
    width:auto !important;
    padding:0 !important;
    margin:0 !important;
    font-size:inherit !important;
    font-family: inherit !important;
    line-height: inherit !important;
    height:auto !important;
    white-space: normal !important;
    border:0 !important;
    color:inherit !important;
}
.reset-sap-styles h1,
.reset-sap-styles h1 span,
.reset-sap-styles h1 b,
.reset-sap-styles h2,
.reset-sap-styles h2 span,
.reset-sap-styles h2 b,
.reset-sap-styles h3,
.reset-sap-styles h3 span,
.reset-sap-styles h3 b,
.reset-sap-styles h4,
.reset-sap-styles h4 span,
.reset-sap-styles h4 b {
    font-size: 17px !important;
    font-family:var(--font-palfinger-family) !important;
    font-weight: normal !important;
}
@media screen and (min-width: 992px){
    .reset-sap-styles h1,
    .reset-sap-styles h1 span,
    .reset-sap-styles h1 b,
    .reset-sap-styles h2,
    .reset-sap-styles h2 span,
    .reset-sap-styles h2 b,
    .reset-sap-styles h3,
    .reset-sap-styles h3 span,
    .reset-sap-styles h3 b,
    .reset-sap-styles h4,
    .reset-sap-styles h4 span,
    .reset-sap-styles h4 b {
        font-size: 26px !important;
    }
}
