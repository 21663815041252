.list-inline-4 {
    margin-left: -1px;
}
.list-inline-4 > li {
    padding-left: 2px;
    padding-right: 2px;
    margin-left: -1px;
}
.list-inline-20 {
    margin-left: -5px;
}
.list-inline-20>li {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -5px;
}
.list-inline-30 {
    margin-left: -15px;
}
.list-inline-30>li {
    padding-left: 15px;
    padding-right: 15px;
}
.list-inline-40 {
    margin-left: -20px;
}
.list-inline-40>li {
    padding-left: 20px;
    padding-right: 20px;
}
/*.list-inline-30>li:last-child, .list-inline-20>li:last-child {*/
    /*padding-right: 0;*/
/*}*/

/*@media screen and (min-width: 768px) {*/
    /*.list-2-cols {*/
        /*column-count: 2;*/
        /*margin-top: -5px;*/
    /*}*/
    /*.list-3-cols {*/
        /*column-count: 3;*/
        /*margin-top: -5px;*/
    /*}*/
    /*.list-2-cols li,*/
    /*.list-3-cols li {*/
        /*-webkit-column-break-inside: avoid; !* Chrome, Safari *!*/
        /*page-break-inside: avoid;           !* Theoretically FF 20+ *!*/
        /*break-inside: avoid-column;         !* IE 11 *!*/
        /*margin-top: 5px;*/
    /*}*/
/*}*/
.list-with-gutter--20 {
    margin-top: -10px;
}
.list-with-gutter--20 > li {
    margin-top: 10px;
}
@media screen and (min-width: 768px) {
    .list-with-gutter--20 {
        margin-top: -20px;
    }
    .list-with-gutter--20 > li {
        margin-top: 20px;
    }
}

.list-inline-border > li:after {
    content: '|';
    display: inline-block;
    margin-left: 10px;
}

.list-inline-border.list-inline-4 > li:after {
    margin-left: 6px;
}

.list-inline-border > li:last-child:after {
    display: none;
}
