.filter-bar {
    background-image: url('/static/img/backgrounds/bg-overlay-red.svg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 20px;
    position: relative;

    @media screen and (min-width: 768px){
        padding: 25px 20px;
        background-position: right center;
    }

    @media screen and (max-width: 767px){
        position: fixed;
        left: 0;
        right: 0;
        top:0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 1051;
        transform: translateX(100%);
        transition: transform 250ms ease-in-out;
    }
}
.filter-bar--typeahead-overlay .filter-bar__group:not(.filter-bar__typeahead) {
    opacity: .5;
    pointer-events: none;
}
.filter-bar.is-open {
    transform: translateX(0);
}
.filter-bar__close {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
}
.rtl-page .filter-bar__close {
    left: 0;
    right: auto;
}
.filter-bar__group + .filter-bar__group {
    margin-top: 20px;
}
.filter-bar__group__title {
    font-size: 16px;
    line-height:calc(24/16);
    border-bottom:1px solid #fff;
    padding-bottom: 4px;
    margin-bottom: 12px;
}
.filter-bar__group__list li + li{
    margin-top: 10px;
}

/* white filter-bar */
.filter-bar.bg-white {
    background-image: none;
    color: var(--color-dark);
}

/* filter-bar levels */
.filter-bar__level {
    /*position: relative;*/
    margin: 0 -20px;
}
.filter-bar__level__form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
    z-index: 1;
    padding: 20px;
}
.is-open > .filter-bar__level__form {
    transform: translateX(0);
}
.filter-bar__level__toggle {
    padding-left: 5px;
    font-size: 16px;
    line-height: calc(20/16);
    text-transform: uppercase;
    font-family: var(--font-bold-family);
    font-weight: 400;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-grey);
}
/*li:first-child .filter-bar__level__toggle {
    border-top: 1px solid var(--color-grey);
}*/
.filter-bar__level__toggle__img {
    width: 62px;
    height: auto;
    margin-right: 15px;
}
.filter-bar__level__toggle__icon {
    margin-left: auto;
}
.filter-bar__level__toggle__icon > .icon {
    font-size: 26px;
    width: 62px;
    height: 62px;
    padding: 18px 5px;
    border-left: 1px solid var(--color-grey);
}

/*filter form*/
.filter-form__result {
    position: relative;
}
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
}
.loading-spinner:before {
    content: '';
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    box-shadow: 20px 20px var(--color-dark), -20px 20px var(--color-primary), -20px -20px var(--color-dark), 20px -20px var(--color-primary);
    animation: spin ease infinite 4.6s;
}

.loading-overlay {
    position: absolute;
    background: rgba(255, 255, 255, 0.88);
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.loading-overlay--dark {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
@keyframes spin {
    0%,
    100% {
        box-shadow: 20px 20px var(--color-dark), -20px 20px var(--color-primary), -20px -20px var(--color-dark), 20px -20px var(--color-primary);
    }
    25% {
        box-shadow: -20px 20px var(--color-primary), -20px -20px var(--color-dark), 20px -20px var(--color-primary), 20px 20px var(--color-dark);
    }
    50% {
        box-shadow: -20px -20px var(--color-dark), 20px -20px var(--color-primary), 20px 20px var(--color-dark), -20px 20px var(--color-primary);
    }
    75% {
        box-shadow: 20px -20px var(--color-primary), 20px 20px var(--color-dark), -20px 20px var(--color-primary), -20px -20px var(--color-dark);
    }
}
.filter-form__result__load-more {
    margin-top: 20px;

    @media screen and (min-width: 768px) {
        margin-top: 60px;
    }
}

/* filter bar head */
.filter-bar-head {
    margin: -20px -20px 0;
    border-bottom: 2px solid var(--color-primary);
    display: flex;
}
.filter-bar__level__form > .filter-bar-head {
    margin-bottom: 20px;
}
.filter-bar-head__close,
.filter-bar-head__back {
    width: 62px;
    height: 62px;
    margin-left: auto;
}
.filter-bar-head__back {
    margin-left: 0;
    font-size: 26px;
}
.filter-bar-head__title {
    font-size: 16px;
    line-height: calc(20/16);
    padding: 0 15px;
    align-self: center;
}
.filter-bar-head__img {
    width: 38px;
    height: auto;
}

/* filter info bar */
/*
.filter-info-bar {
    border-bottom: 2px solid var(--color-primary);
    display: flex;
}
.filter-info-bar__toggle {
    width: 62px;
    height: 62px;
    margin-left: auto;
}
.filter-info-bar__title {
    font-size: 16px;
    line-height: calc(20/16);
    padding: 0 15px;
    align-self: center;
}*/
