.job-form {
    border-bottom: 1px solid var(--color-grey-light);
}

.table-jobs {
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    border-bottom: 1px solid #f1f1f1;
}

.table-jobs td,
.table-jobs th {
    padding: 9px 15px 12px;
}

.table-jobs th {
    font-size: 16px;
    padding-bottom: 8px;
}

@media screen and (min-width: 768px) {
    .table-jobs td:first-child,
    .table-jobs th:first-child {
        padding-left: 29px;
    }

    .table-jobs .table-jobs__show-detail {
        top: 8px;
    }
}

.table-jobs .table-jobs__td-show-detail {
    position: relative;
    overflow: hidden;
    width: 90px;
}

.table-jobs__description {
    color: var(--color-primary);
    font-size: 16px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f1f1f1;
}

.table-responsive .xs-title {
    display: none;
}

/* mobile version of table*/
@media screen and (max-width: 767px) {
    /*.table-jobs .tbody {*/
        /*display: table;*/
        /*width: 100%;*/
    /*}*/

    .table-jobs tr {
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
    }

    .table-jobs th {
        display: none;
    }

    .table-jobs td {
        display: block;
    }

    .table-jobs td:first-child {
        padding-top: 20px
    }

    .table-responsive .xs-title {
        display: block;
    }

    .table-jobs .xs-title {
        display: block;
        font-size: 12px;
        line-height: 1;
    }

    .table-jobs .table-jobs__td-show-detail {
        height: 34px;
        width: auto;
    }

}
