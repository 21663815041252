.form-block {
    padding: 35px 30px 30px;
    background: #f1f1f1;
}
/*parsley*/
.parsley-errors-list {
    font-size: 13px;
    line-height:calc(20/14);
    padding: 0;
    margin: 2px 0 0;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    text-align: right;
}
.form-errors {
    position: relative;
}
.form-errors:before {
    content: '';
    font-family: "iconfont";
    position: absolute;
    top: 12px;
    right: 11px;
    font-size: 12px;
    line-height: 1;
}
.rtl-page .form-errors:before {
    left: 11px;
    right: auto;
}
.has-success:before {
    content: var(--icon-checked);
    font-family:iconfont;
}
.checkbox-styled .has-success:before {
    display: none;
}
.has-error:before {
    content: var(--icon-attention);
    font-family:iconfont;
    font-size: 15px;
}
.has-error .parsley-errors-list,
.has-error:before {
    color: var(--color-danger);
}
.has-success .parsley-errors-list,
.has-success:before {
    color: var(--color-success);
}

.form-group--sm label {
    margin-bottom: 2px;
}
.form-group--sm {
    margin-bottom: 8px;
}

.rtl-page .form-control.hasDatepicker {
    padding-right:40px;
}

.form--bg-red {
    background: var(--color-primary);
    background-image: url('/static/img/backgrounds/bg-overlay-red.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px;
    padding-bottom: 20px;
}

.form--bg-red label {
    color: white;
}

.dealer-search__form__title {
    color: var(--color-primary);
    margin-top: 10px;

    @media screen and (max-width: 767px) {
        color: white;
        margin-top: 0;
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 768px) {
    .dealer-search__form {
        margin-bottom: 40px;
    }
}

.checkbox-styled .has-error:before {
    margin-right: 8px;
}
.checkbox-styled .has-error +.parsley-errors-list {
    color: var(--color-danger);
}
.form-errors--checkbox-list:before {
    content: none;
}
.form-errors--checkbox-list .parsley-errors-list {
    padding-left: 25px;
    position: relative;
}
.form-errors--checkbox-list.has-error .parsley-errors-list:before {
    content: var(--icon-attention);
    font-family:iconfont;
    font-size: 15px;
    position: absolute;
    left: 0;
    top: -2px;
}

/* formbuilder overrides */

/*.formbuilder-form {*/
    /*padding: 35px 30px 30px;*/
    /*background: #f1f1f1;*/
/*}*/

.formbuilder-form input[type="checkbox"],
.formbuilder-form input[type="radio"]{
    display: none;
}

.formbuilder-form .checkbox,
.formbuilder-form .radio,
.formbuilder-form .checkbox-inline,
.formbuilder-form .radio-inline {
    position: relative;
    padding-left: 25px;
}
.rtl-page .formbuilder-form .checkbox,
.rtl-page .formbuilder-form .radio,
.rtl-page .formbuilder-form .checkbox-inline,
.rtl-page .formbuilder-form .radio-inline {
    padding-left: 0;
    padding-right: 25px;
}

.formbuilder-form .radio label,
.formbuilder-form .checkbox label {
    padding-left: 0;
    padding-right: 0;
}

.formbuilder-form .checkbox a,
.formbuilder-form .checkbox-inline a,
.formbuilder-form .radio a,
.formbuilder-form .radio-inline a {
    color:var(--color-primary);
}

.formbuilder-form .checkbox span,
.formbuilder-form .checkbox-inline span,
.formbuilder-form .radio span,
.formbuilder-form .radio-inline span
{
    /*overflow: hidden;*/
    vertical-align: middle;
    border: 1px solid var(--color-grey);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background: #ffffff;
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 14px;
    color: white;
    text-indent: -999em;
    position: absolute;
    left: 0;
    top: 8px;
    @media screen and (max-width: 767px) {
        top: 5px;
    }
}
.rtl-page .formbuilder-form .checkbox span,
.rtl-page .formbuilder-form .checkbox-inline span,
.rtl-page .formbuilder-form .radio span,
.rtl-page .formbuilder-form .radio-inline span {
    right: 0;
    left: auto;
}

.formbuilder-form .radio span,
.formbuilder-form .radio-inline span,
.formbuilder-form .radio span:after,
.formbuilder-form .radio-inline span:after {
    border-radius: 50%;
}

.formbuilder-form .radio input[type=radio]:checked~span,
.formbuilder-form .radio-inline input[type=radio]:checked~span,
.formbuilder-form .checkbox-inline input[type=checkbox]:checked~span,
.formbuilder-form .checkbox input[type=checkbox]:checked~span {
    border: 1px solid var(--color-dark);
}

.formbuilder-form .radio input[type=radio]:checked~span:after,
.formbuilder-form .radio-inline input[type=radio]:checked~span:after,
.formbuilder-form .checkbox-inline input[type=checkbox]:checked~span:after,
.formbuilder-form .checkbox input[type=checkbox]:checked~span:after {
    content: var(--icon-checked);
    font-family: "iconfont";
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    font-size: 10px;
    background: var(--color-dark);
    border-color: var(--color-dark);
    text-indent: 0;
    text-align: center;
}
.formbuilder-form .radio input[type=radio]:checked span:after,
.formbuilder-form .radio-inline input[type=radio]:checked span:after {
    content: ' ';
    left:4px;
    top:4px;
    border:0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #000;
}

.formbuilder-form .form-errors {
    position: static;
}

.formbuilder-form .form-errors:before {
    top: 38px;
}
.formbuilder-form .select .form-errors:before {
    top: 15px;
    z-index: 1;
    background: #fff;
}
@media (min-width: 768px) {
    .formbuilder-form .form-errors:before {
        top: 41px;
    }
}

.formbuilder-form .form-group {
    position: relative;
}

.formbuilder-form .has-error + .form-control {
    border-bottom-color: var(--color-danger);
}

.formbuilder-form .has-success + .form-control {
    border-bottom-color: var(--color-success);
}

.formbuilder-form .has-calendar-icon .form-errors:before {
    right: 40px;
}

.checkbox .form-errors:before {
    display: none;
}

.checkbox .parsley-errors-list {
    position: static;
}

.custom-input-area {
    position:absolute;
    left:-2000px;
}
