.mobile-overlay {
  display: block;
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 0;
  transform: translateX(100%);
  transition: transform 200ms ease-in-out;
  border-top:1px solid #000;
  background: #18191d;
  background: radial-gradient(ellipse at center right,  #313235 0%,#18191d 100%);
  color: #fff;
  z-index: 1100;
}

.mobile-overlay.is-open {
  transform: translateX(0);
}

.mobile-overlay__content {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
  overflow-x: hidden;
  font-size: 16px;
}

.mobile-overlay__header {
  height: 60px;
}

.mobile-overlay__close {
  position: relative;
  float: right;
  padding: 20px 15px;
  background-color: transparent;
  background-image: none;
  border: 0;
}

.mobile-overlay__close .icon-bar {
  display: block;
  width: 24px;
  height: 2px;
  background: #fff;
}

.mobile-overlay .icon-bar + .icon-bar {
  margin-top: 6px;
}

/*.mobile-overlay__close .icon-bar:nth-child(2) {*/
  /*transform: rotate(45deg) translateX(5px) translateY(6px);*/
/*}*/
/*.mobile-overlay__close .icon-bar:nth-child(3) {*/
  /*transform: rotate(-45deg) translateX(6px) translateY(-6px);*/
/*}*/
