.benefit-block {
    font-size: 14px;
    line-height: calc(25/14);
}
.benefit-block__subtitle {
    font-size: 10px;
    line-height: calc(24/10);
    margin-top: 10px;

    @media screen and (min-width: 768px) {
        margin-top: 38px;
    }
}
.benefit-block__title {
    font-size: 24px;
    line-height: calc(30/28);
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        margin-bottom: 15px;
        font-size: 28px;
    }
}
.benefit-block__text {
    margin-bottom: 15px;

    @media screen and (min-width: 768px){
        margin-bottom: 50px;
    }
}

