.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: 30px;
}

@media screen and (min-width: 768px) {
    .content-block {
        scroll-margin-top: 100px;
    }
    .content-block + .content-block,
    .pimcore_area_content + .pimcore_area_content > .content-block {
        margin-top: 60px;
    }
}

.content-block--sm + .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block--sm {
    margin-top: 20px;
}
@media screen and (min-width: 768px) {
    .content-block--sm + .content-block--sm,
    .pimcore_area_content + .pimcore_area_content > .content-block--sm {
        margin-top: 30px;
    }
}

.content-block--bordered + .content-block--bordered {
    border-top: 1px solid var(--color-grey-light);
    padding-top: 60px;
}
.content-block--sm.content-block--bordered + .content-block--sm.content-block--bordered {
    padding-top: 30px;
}