.btn-gray {
  color: black;
  background-color: #f1f1f1;
  border: 1px solid var(--color-grey-light);
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-gray:focus,
.btn-gray.focus,
.btn-gray:hover
{
  color: black;
}
.btn-gray:hover {
  background-color: #e0e0e0;
}

.btn-group--gray .btn {
  text-transform: none;
  font-family: var(--font-regular-family);
}

.btn-group.btn-group--gray > .btn:first-child  {
  border-right: none;
}

.btn-group.btn-group--gray > .btn:last-child  {
  border-left: none;
}

.btn-group.btn-group--gray > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-left: none;
  border-right: none;
}

.btn-group.btn-group--gray > .btn:not(:last-child):after {
  content: '';
  background: var(--color-grey-light);
  width: 1px;
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  z-index: 1;
}