:root {
    --color-primary: #c91019;
    --color-primary-dark: #a60d14;

    --color-secondary: #ffe300;

    --color-danger: #ea4848;
    --color-warning: #f6952e;
    --color-success: #43b36c;

    --color-grey: #999999;
    --color-grey-light: #d9dbdb;
    --color-dark: #1b1b1b;


    /*!**** Fonts ****!*/
    --font-light-family: 'TradeGothicNext-Light', sans-serif;
    --font-regular-family: 'TradeGothicNext-Regular', sans-serif;
    --font-bold-family: 'TradeGothicNext-Bold', sans-serif;
    --font-palfinger-family: 'HandelGotD-Bol', sans-serif;
}