.select {
    position: relative;
}
.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 8px 25px 8px 10px;
}
.select select::-ms-expand {
    display: none;
}
.select:after {
    display: inline-block;
    font-family: iconfont;
    line-height: 1;
    content: var(--icon-arrow-solid-down);
    position: absolute;
    font-size: 5px;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;
    color: inherit;

    @media screen and (min-width: 768px){
        right: 12px;
    }
}

/* select colors */
.select--white-border {
    color: #fff;
}
.select--white-border > .form-control {
    background: transparent;
    border: 1px solid #fff;
    box-shadow: none;
    color: #fff;
}
.select--white-border > select > option {
    color: var(--color-dark);
}

.form-inline .select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}