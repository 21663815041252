.wysiwyg-box {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #f1f1f1;
    padding: 25px 30px;
    font-size: 20px;
    line-height: calc(33/20);
}

.wysiwyg-box ul {
    padding-left: 20px;
    margin: 0;
}
.wysiwyg-box ul li + li {
    margin-top: 15px;
}
.wysiwyg a:not(.btn) {
    color: var(--color-primary);
}

/* fix images in wysiwyg */
@media screen and (max-width: 767px) {
    .wysiwyg img {
        width:auto !important;
        max-width:100% !important;
    }
}