.twitter-typeahead {
    /*override inline stlye*/
    display: block !important;
    /*color: #4c4c4c;*/
    /*text-align: left;*/
}
.tt-input,
.tt-input:focus {
    box-shadow: none;
}
.tt-menu {
    right: 0;
    background-color: #fff;
    color: #000;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 6px rgba(7,9,9,.25);
    padding: 7px 12px;
    z-index: 100;
    position: relative;
    min-width: 130px;
}
.tt-dataset-heading {
    position: relative;
    padding: 5px 0 5px 25px;
    border-bottom: 1px solid #dfe1e6;
    margin-bottom: 5px;
}
.tt-dataset-heading-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.tt-dataset + .tt-dataset {
    margin-top: 10px;
}
.tt-dataset:empty {
    display: none;
}
.tt-suggestion {
    padding: 2px 0;
}
.tt-selectable:hover,
.tt-selectable.tt-cursor {
    cursor: pointer;
    /*background: #f0f0f2;*/
    color: var(--color-primary);
    text-decoration: underline;
}
.tt-suggestion mark {
    background: none;
    padding: 0;
    color: inherit;
    font-family: var(--font-bold-family);
}
.tt-empty-container {
    padding: 0 10px;
}
