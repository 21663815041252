@media screen and (max-width: 767px){
  html.has-open-nav, .has-open-nav body {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .navbar-main {
    height: 60px;
    transition: background 200ms ease-in-out;
  }
  .navbar-main.is-open,
  .navbar-main.navbar--paldrive {
    background: var(--color-dark);
    background-image: url(/static/img/backgrounds/bg-overlay-grey.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: fixed; /* page-info */
  }
  .mobile-nav .navbar-nav {
    margin: 0 0 30px;
  }
  .navbar-nav li:first-child {
    border-top:1px solid #767676;
  }
  .navbar-nav li {
    border-bottom:1px solid #767676;
  }
  .navbar-nav li>a {
    padding: 15px 35px 15px 0;
  }
  .navbar-main__dropdown li>a {
    padding: 10px 20px 10px 0;
  }
  .navbar-main__dropdown li:last-child {
    border-bottom: 0;
  }

  .mobile-nav {
    display: block;
    position: fixed;
    top: 60px;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
    border-top:1px solid #000;
    background: #18191d;
    background: radial-gradient(ellipse at center right,  #313235 0%,#18191d 100%);
    color: #fff;
    z-index: 1000;
  }
  .mobile-nav__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 20px 15px;
    font-size: 16px;
  }
  .mobile-nav.is-open {
    transform: translateX(0);
  }

  .navbar-nav__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 8px;
  }

  /*toggle button*/
  .navbar-toggle .icon-bar {
    transition: transform 250ms ease-in-out;
  }
  .is-open .icon-bar:nth-child(2) {
    transform: rotate(45deg) translateX(5px) translateY(6px);
  }
  .is-open .icon-bar:nth-child(3) {
    transform: scale(0);
    transform-origin: center center;
  }
  .is-open .icon-bar:nth-child(4) {
    transform: rotate(-45deg) translateX(6px) translateY(-6px);
  }
}