html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content*/
}
a {
    color: inherit;
    cursor: pointer;
}
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
address {
    margin-bottom: 0;
}
textarea {
    resize: vertical;
}
label {
    font-weight: 400;
    font-family: var(--font-bold-family);
}
/*dt,*/
th {
    font-weight: normal;
    font-family: var(--font-bold-family);
}
::-moz-selection {
    background: var(--color-primary);
    color: #fff;
}

::selection {
    background: var(--color-primary);
    color: #fff;
}
mark {
    color: inherit;
    padding: 0;
    background: none;
    font-family: var(--font-bold-family);
}
s {
    text-decoration: none;
}