.benefit {
    font-size: 16px;
    line-height: calc(24/20);
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        font-size: 20px;
        margin-bottom: 15px;
    }
}
.benefit__icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
}
.benefit__icon__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    height: auto;
}