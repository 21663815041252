.subnav {
    background-image: linear-gradient(270deg, #3f3f3f 0%, #1b1b1b 100%);
    padding: 0;
    margin: 0;
    width: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid var(--color-grey-light);

    @media screen and (min-width: 768px) {
        height:70px;
    }

    @media screen and (min-width: 1600px) {
        height:77px;
    }
}

@media screen and (min-width: 1920px) {
    .subnav li:first-child {
        overflow: hidden;
    }
}
@media screen and (min-width: 768px){
    .subnav--affix {
        position: relative;
        z-index: 999;
    }
    .subnav li {
        float: left;
        margin-right: -5px;
    }
    .subnav ul:not(.cta-area) li {
        margin-right: 0;
    }
    .rtl-page .subnav li {
        float: right;
        margin-right: 0;
        margin-left: -5px;
    }
    .rtl-page .subnav ul:not(.cta-area) li {
        margin-left: 0;
    }
    .subnav__item {
        position: relative;
        display: inline-block;
        color: #fff;
        padding: 23px 16px 20px 16px;
        height: 70px;
        font-size: calc(12rem/10);
        text-transform: uppercase;
    }
    .subnav__item--with-children:after {
        content:'';
        display: inline-block;
        width: 6px;
        height:6px;
        border:2px solid var(--color-primary);
        transform: rotate(45deg) translateY(-4px);
        border-top: 0;
        border-left: 0;
        margin-left: 10px;
    }
    .subnav__list:hover .subnav__item {
        color: #939393;
    }
    li:hover .subnav__item,
    li.is-active .subnav__item,
    .subnav__item:hover,
    .subnav__item:focus {
        color: #fff !important;
    }
    li:hover .subnav__item:before,
    li.is-active .subnav__item:before,
    .subnav__item:hover:before,
    .subnav__item:focus:before {
        content:'';
        position: absolute;
        left:16px;
        right:16px;
        bottom:0;
        height:5px;
        width:auto;
        background:var(--color-primary);
    }

    /*first nav item*/
    li:first-child .subnav__item {
        background: #fff;
        margin-left: -15px;
        margin-right: 15px;
        transform: skew(-20deg);
        padding: 11px 10px 11px 14px;
        display: flex;
        align-items: center;
    }
    .rtl-page li:first-child .subnav__item {
        margin-left: 15px;
        margin-right: -15px;
        padding-left: 5px;
        padding-right: 9px;
    }
    .subnav__item__text__img {
        max-width: 38px;
        height: auto;
        transform: skew(20deg);
        margin-left:10px;
    }
    li:first-child .subnav__item:before {
        right:0 !important;
    }
}

@media screen and (min-width: 1279px) and (max-width: 1599px) {
    .subnav__item {
        padding: 20px 16px;
        height: 70px;
        font-size: calc(15rem/10);
    }
    li:first-child .subnav__item {
        padding: 11px 12px 11px 14px;
    }
    .subnav__item__text__img {
        max-width: 45px;
    }
    /*.subnav ul:not(.cta-area) li {
        margin-right: 30px;
    }
    .rtl-page .subnav ul:not(.cta-area) li {
        margin-left: 30px;
    }*/
}

@media screen and (min-width: 1600px) {
    .subnav__item {
        padding: 23px 16px;
        height: 77px;
        font-size: calc(16rem/10);
    }
    li:first-child .subnav__item {
        padding: 11px 30px;
    }

    .subnav__item__text__img {
        max-width: 60px;
    }
    /*.subnav ul:not(.cta-area) li {
        margin-right: 40px;
    }
    .rtl-page .subnav ul:not(.cta-area) li {
        margin-left: 40px;
    }*/
}

.subnav__toggle {
    background: transparent;
    border: 0;
    color: #fff;
    display: block;
    width: 100%;
    height: 50px;
    position: relative;
    text-align: left;
    padding-left: 85px;
    padding-right: 25px;
}
.subnav__toggle__category {
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    width: 74px;
    height: 50px;
    padding: 5px;
    transform: skew(-20deg);
    background: #fff;
}
.rtl-page .subnav__toggle__category {
    left: auto;
    right: -10px;
    transform: skew(20deg);
}
.subnav__toggle__category__img {
    transform: skew(20deg);
    max-width: 40px;
    height: auto;
}
.rtl-page .subnav__toggle__category__img {
    transform: skew(-20deg);
}
.subnav__toggle__icon, .subnav__dropdown-menu__icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4px;
    transition: transform 250ms ease-in-out;
}
.rtl-page .subnav__toggle__icon, .rtl-page .subnav__dropdown-menu__icon {
    left: 15px;
    right: auto;
    transform: translateY(-50%) scaleX(-1);
}
.subnav__dropdown-menu__icon {
    font-size:12px;
    /*color:var(--color-primary);*/
}
.open .subnav__toggle__icon {
    transform: translateY(-50%) rotate(180deg);
}
.subnav__dropdown-menu  {
    display: block;
    position: fixed;
    left:100%;
    top:0;
    bottom:0;
    z-index: 9999;
    width: 100%;
    padding:0;
    margin-top: 0;
    line-height: 1.33;
    height:100vh;
    transform: translateX(10px);
    transition: transform 200ms ease-in-out;
}
.open > .subnav__dropdown-menu {
    transform: translateX(-100%);
}
.subnav__dropdown-menu li:first-child {
    border-bottom:1px solid #fff;
    margin-bottom:10px;
    height:52px;
    line-height:52px;
    font-size: 16px;
    overflow: hidden;
}
.subnav__dropdown-menu .subnav__dropdown-title,
.subnav__dropdown-menu .subnav__dropdown-back {
    display: inline-block;
    padding-left:20px;
    font-size: 16px;
}

.subnav__dropdown-back .subnav__dropdown-back__icon {
    position: relative;
    font-size: 8px;
    vertical-align: 0.2em;
    margin-right: 6px;
}
.subnav__dropdown-menu .subnav__dropdown-title {
    padding-left:0;
    padding-right:20px;
}
.subnav__dropdown-menu li>a {
    color: #fff;
    font-size: 16px;
    padding: 14px 20px;
    position: relative;
    display: block;
}
.subnav__dropdown-menu li.is-active>a {
    color: var(--color-primary);
}
/*.subnav__dropdown-menu button.subnav-toggle {
    position: relative;
    color: #fff;
    font-size: 16px;
    padding: 14px 20px;
    float:right;
    text-align: center;
    border:0;
    -webkit-appearance: none;
    display: block;
    width: 50px;
    height:50px;
    line-height:40px;
    background: transparent;
}*/

.subnav__dropdown-menu li.is-active>a:not(.subnav-toggle) {
    color: var(--color-primary);
}