.checkbox-styled{
    position: relative;
}

.checkbox-styled .parsley-errors-list li{
    text-align: left;
}

.parsley-errors-list *{
    color: var(--color-danger);
}

.contact-slide .parsley-errors-list li {
    color: white;
}

.checkbox-styled input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.checkbox-styled__box {
    overflow: hidden;
    vertical-align: middle;
    border: 1px solid var(--color-grey);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background: #ffffff;
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 14px;
    color: white;
    text-indent: -999em;
    position: absolute;
    left: 0;
    top: 2px;
}
.rtl-page .checkbox-styled__box {
    left: auto;
    right: 0;
}
.checkbox-styled input[type="checkbox"]:checked + .checkbox-styled__box {
    background: var(--color-dark);
    border-color: var(--color-dark);
    text-indent: 0;
}
.checkbox-styled input[type="checkbox"]:focus ~ .checkbox-styled__box {
    border-color: #2f8acf;
}
.has-error .checkbox-styled .checkbox-styled__box {
    border-color: var(--color-danger);
}

.checkbox-styled.is-disabled {
    opacity: 0.8;
}

.checkbox-styled.is-disabled label {
    cursor: default;
}


.checkbox-styled label {
    font-size: 16px;
    font-family: var(--font-regular-family);
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    line-height: 1.2;
    padding-left: 25px;
    position: relative;
}
.rtl-page .checkbox-styled label {
    padding-left: 0;
    padding-right: 25px;
}

.checkbox-styled__text a {
    text-decoration: underline;
}

.text-white .checkbox-styled__text a:hover {
    color: var(--color-grey-light);
}