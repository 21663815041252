.range-slider {
    height: 4px;
    background: #fff;
    border-radius: 0;
    border: 0;
    padding: 0 28px;
}
.range-slider .noUi-handle {
    transform: skew(-20deg);
    width: 27px;
    height: 20px;
    top: -8px;
    background-image: linear-gradient(270deg, #3f3f3f 0%, #1b1b1b 100%);
    box-shadow: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    left: -13px;
}
.range-slider .noUi-handle:before {
    background: transparent;
    height: auto;
    width: auto;
    content: var(--icon-handle-bar-lines);
    font-family:iconfont;
    font-size: 8px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) skew(20deg);
    color: white;
}
.range-slider .noUi-handle:after {
    content: none;
}

.range-slider__values {
    padding: 8px 10px 0;
}
.range-slider__values__input {
    background: transparent;
    border: 0;
    height: 25px;
    width: 100%;
    cursor: default;
    pointer-events: none;
}

.range-slider .noUi-connect {
    background: var(--color-primary);
}