@media screen and (min-width: 768px) {
    .product-feature-area {
        overflow: hidden;
        margin: 0;
        padding: 0;
        text-align: center;
        border-left: 1px solid #d9dbdb;
    }
    .rtl-page .product-feature-area {
        border-left: none;
        border-right: 1px solid #d9dbdb;
    }
    .product-feature-area__item {
        transform:skewX(-20deg);
        width: 25%;
        height: 92px;
        background-color: #ffffff;
        border-top: 1px solid #d9dbdb;
        border-bottom: 1px solid #d9dbdb;
        position: relative;
    }
    .product-feature-area__item:nth-last-child(2) {
        border-right: 1px solid #d9dbdb;
    }
    .rtl-page .product-feature-area__item:nth-last-child(2) {
        border-right: none;
        border-left: 1px solid #d9dbdb;
        z-index:1;
    }
    .product-feature-area__item:first-child {
        margin-left: -18px;
        padding-left: 18px;
        width: calc(25% + 18px);
    }
    .rtl-page .product-feature-area__item:first-child {
        margin-left: 0;
        margin-right: -18px;
        padding-left: 0;
        padding-right: 18px;
    }
    .product-feature-area__item + .product-feature-area__item:before {
        content: "";
        width: 1px;
        height: 56px;
        top: 18px;
        position: absolute;
        background: #d9dbdb;
        left: 0;
    }
    .rtl-page .product-feature-area__item + .product-feature-area__item:before {
        left: auto;
        right: 0;
    }
    .product-feature-area__item__content {
        transform:skewX(20deg);
        padding: 12px;
        justify-content: center;
    }
    .product-feature-area__item__button {
        margin-left: 10px;
        transform:skewX(-20deg);
        width: 24%;
        height: 92px;
        position: relative;
    }
    .rtl-page .product-feature-area__item__button {
        margin-left: 0;
        margin-right: 10px;
    }
    .product-feature-area__item__button:after {
        position: absolute;
        right: -18px;
        top: 0;
        bottom: 0;
        content: "";
        background-color: #f0f0f0;
        width: 36px;
        transform: skew(20deg);
        transition: background-color 0.15s ease;
    }
    .rtl-page .product-feature-area__item__button:after {
        right: auto;
        left: -18px;
    }
    .product-feature-area__text {
        font-size: 13px;
    }
    .product-feature-area__value {
        font-size: 22px;
    }
    .product-feature-area__icon {
        margin-left: 12px;
    }
    .rtl-page .product-feature-area__icon {
        margin-left: 0;
        margin-right: 12px;
    }
    .product-feature-area__feature-text {
        color: var(--color-primary);
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .product-feature-area {
        justify-content: center;
    }
    .product-feature-area__item + .product-feature-area__item {
        border-left: 1px solid #d9dbdb;
    }
    .rtl-page .product-feature-area__item + .product-feature-area__item {
        border-left: none;
        border-right: 1px solid #d9dbdb;
    }
    .product-feature-area__icon {
        margin-right: 8px;
    }
    .rtl-page .product-feature-area__icon {
        margin-right: 0;
        margin-left: 8px;
    }
    .product-feature-area__item__button {
        margin-top: 8px;
    }
    .product-feature-area__text--mobile-bold{
        font-family: var(--font-bold-family);
    }
}
.product-feature-area {
    display: flex;
    flex-direction: row;
}
.product-feature-area__icon {
    font-size: 12px;
}
.product-feature-area__text {
    font-size: 11px;
}
.product-feature-area__text a:not(.btn) {
    margin:1px 4px;
    display: inline-block;
    text-decoration: underline;
    color: var(--color-primary);
}
.product-feature-area__text a:not(.btn):hover,
.product-feature-area__text a:not(.btn):focus {
    text-decoration: none;
}
.product-feature-area__value {
    color: var(--color-primary);
    font-size: 16px;
    font-family: var(--font-bold-family);
    margin: 4px 0;
}

.product-feature-area__item {
    display: inline-block;
    width: 33%;
}
.product-feature-area__item__button {
    width: 100%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    transition: background-color 0.15s ease;
}
.product-feature-area__item__button:hover,
.product-feature-area__item__button:focus,
.product-feature-area__item__button:hover:after,
.product-feature-area__item__button:focus:after {
    background-color: #d9dbdb;
}
.product-feature-area__item__button .product-feature-area__item__content {
    flex-direction: row;
}
.product-feature-area__item__content {
    text-decoration: none;
    height: 100%;
    width: 100%;
    padding: 10px 8px;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    line-height: 1.2;
    text-align: center;
}
.product-feature-area__feature-text {
    color: var(--color-primary);
    font-size: 11px;
}