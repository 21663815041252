.download-teaser {
    border-top:1px solid var(--color-grey-light);
    padding: 15px 0;
}
.download-teaser__body {
    padding: 2px 15px;
    line-height: calc(20/16);
}

.download-teaser__body .download-teaser__body {
    padding: 0;
}
.download-teaser__body__detail {
    margin-top: 10px;
    @media screen and (min-width: 768px) {
        margin-top: 25px;
    }
}
.download-teaser__body__detail__download {
    margin-left: 28px;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
        display:block;
        margin:5px 0;
    }
}
.rtl-page .download-teaser__body__detail__download {
    display: block;
    margin-top:6px;
}
.download-teaser__body__detail__download>.icon {
    font-size: 11px;
    vertical-align: 0.1em;
    margin:0 5px;
}
.download-teaser__body__subtitle {
    word-break: break-all;
}
.download-teaser__button {
    background-color: #F0F0F0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    transition: background-color 0.15s ease;
    line-height: 1;
    margin-top: 20px;
    width: 45%;

    @media screen and (min-width: 768px) {
        width: 50%;
    }
}
.download-teaser__button + .download-teaser__button {
    margin-left: 20px;

    @media screen and (min-width: 768px) {
        margin-right: -25px;
    }
}
.rtl-page .download-teaser__button + .download-teaser__button {
    margin-left: 0;
    margin-right: 20px;

    @media screen and (min-width: 768px) {
        margin-left: -25px;
    }
}
.download-teaser__button:hover {
    background-color: #e0e0e0;
}
.download-teaser__button__icon{
    font-size: 12px;
    margin-right: 12px;
}
.rtl-page .download-teaser__button__icon {
    margin-right: 0;
    margin-left: 12px;
}

/* small download teaser */
.download-teaser--sm {
    border-top: 0;
    padding: 0;
}
.download-teaser--sm .download-teaser__body__detail {
    width: 100%;
}
.download-teaser--sm .download-teaser__body__detail__download {
    margin-left: 0;
}
.download-teaser--sm .download-teaser__body {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-content: space-between;
}