/*Center cols*/
.row--inline-block {
    font-size: 0;
    text-align: center;
}
.row--inline-block>.col {
    display: inline-block;
    float: none;
    font-size: 1rem;
    vertical-align: middle;
    text-align: left;
}
.rtl-page .row--inline-block>.col {
    text-align: right;
}
.row--inline-block--bottom>.col {
    vertical-align: bottom;
}

/* row--same-height */
.row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to increase the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.row--same-height,
.row--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    /*align-items: stretch;*/
    /*justify-content: space-between;*/
    /*overflow: hidden;*/
}
.row--same-height {
    flex-wrap: wrap;
}
.row--same-height.slick-slider {
    display: block;
}
.row--same-height:before,
.row--same-height:after {
    display: none;
}
.row--same-height > .col,
.row--same-height.slick-slider .col {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom:1px;
}

.row--same-height__item--justify-end {
    justify-content: flex-end;
}
.row--same-height__item--justify-between {
    justify-content: space-between;
}

.row--same-height--align-end {
    align-items: flex-end;
}
.row--same-height--align-center {
    align-items: center;
}

@media screen and (min-width: 768px) {
    .row--same-height-sm .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height-sm,
    .row--same-height-sm.slick-slider .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        /*align-items: stretch;*/
        /*justify-content: space-between;*/
        /*overflow: hidden;*/
    }
    .row--same-height-sm.slick-slider {
        display: block;
    }
    .row--same-height-sm:before,
    .row--same-height-sm:after {
        display: none;
    }
    .row--same-height-sm > .col,
    .row--same-height-sm.slick-slider .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}

.row.row--gutter-with-0 {
    margin-left: 0;
    margin-right: 0;
}
.row.row--gutter-with-0>.col {
    padding-left: 0;
    padding-right: 0;
}
.row.row--gutter-with-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row.row--gutter-with-10>.col {
    padding-left: 5px;
    padding-right: 5px;
}

.row.row--gutter-with-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row.row--gutter-with-20>.col {
    padding-left: 10px;
    padding-right: 10px;
}

.row.row--gutter-with-60 {
    margin-left: -30px;
    margin-right: -30px;
}
.row.row--gutter-with-60>.col {
    padding-left: 30px;
    padding-right: 30px;
}

.row.row--gutter-with-82 {
    margin-left: -41px;
    margin-right: -41px;
}
.row.row--gutter-with-82>.col {
    padding-left: 41px;
    padding-right: 41px;
}

/*vertical gutter*/
.row--vertical-gutter-base:not(.slider) {
    margin-top: -10px;
    clear: both; /* fix row-same-height combi*/

    @media screen and (min-width: 768px){
        margin-top: -30px;
    }
}
.row--vertical-gutter-base:not(.slider) > .col {
    margin-top: 10px;

    @media screen and (min-width: 768px){
        margin-top: 30px;
    }
}

.row--vertical-gutter-15 {
    margin-top: -15px;
}
.row--vertical-gutter-15 > .col {
    margin-top: 15px;
}

.row--vertical-gutter-20 {
    margin-top: -20px;
}
.row--vertical-gutter-20 > .col {
    margin-top: 20px;
}
.row--vertical-gutter-30 {
    margin-top: -30px;
}
.row--vertical-gutter-30 > .col,
.row--vertical-gutter-30 .slick-track > .col {
    margin-top: 30px;
}

.row--vertical-gutter-60 {
    margin-top: -30px;

@media screen and (min-width: 768px){
    margin-top: -60px;
}
}
.row--vertical-gutter-60 > .col {
    margin-top: 30px;

@media screen and (min-width: 768px){
    margin-top: 60px;
}
}

/*bordered cols*/
.row-bordered__wrapper {
    overflow: hidden;
}
.row--bordered > .col {
    border-top: 1px solid var(--color-grey);
}
.row--bordered > .col:last-child {
    border-bottom: 1px solid var(--color-grey);
}
.row--bordered--no-bottom-border > .col:last-child {
    border-bottom:0;
}
.row--bordered--padding > .col {
    padding-top: 30px;
    padding-bottom: 30px;
}

@media screen and (max-width: 767px){
    .row--same-height--scroll-xs {
        flex-wrap: inherit;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.row--delimiter > .col + .col:before {
    content: '';
    position: absolute;
    left: -15px;
    top: 20px;
    bottom: 20px;
    width: 1px;
    background: #fff;
}