@media screen and (min-width: 768px) {
    .subnav__li,
    .second-level-subnav__li,
    .third-level-subnav__li{
        position: relative;
    }
    .second-level-subnav__li,
    .third-level-subnav__li{
        position: static;
        width: 100%;
        display: block;
    }
    .third-level-subnav,
    .second-level-subnav {
        list-style: none;
        margin: 0;
        padding: 20px 0;
        display: none;
        position: absolute;
        left:-15px;
        top:100%;
        background: #1b1b1b;
        width: 300px;
        margin-top:-1px;
    }
    .third-level-subnav {
        top:0;
        left:100%;
        margin-top:0;
        min-height:100%;
    }
    .third-level-subnav:before {
        content: '';
        position: absolute;
        left:0;
        top:35px;
        bottom:35px;
        width:1px;
        background: #999999;
        height: auto;
    }
    .second-level-subnav__li--with-children:hover .third-level-subnav,
    .subnav__li--with-children:hover .second-level-subnav {
        display: block;
    }
    .second-level-subnav__item,
    .third-level-subnav__item {
        display: block;
        position: relative;
        padding: 20px 35px;
        line-height: 1.25;
        font-size: calc(15rem/10);
        color: #fff;
        text-decoration: none;
    }
    .second-level-subnav__item--with-children:after {
        position: absolute;
        right: 20px;
        top: 50%;
        content:'';
        display: inline-block;
        width: 6px;
        height:6px;
        border:2px solid var(--color-primary);
        transform: rotate(-45deg) translateY(-50%);
        border-top: 0;
        border-left: 0;
    }
    .second-level-subnav:hover .second-level-subnav__item,
    .third-level-subnav:hover .third-level-subnav__item {
        color: #939393;
    }
    .second-level-subnav__li:hover .second-level-subnav__item,
    .third-level-subnav__li:hover .third-level-subnav__item,
    .second-level-subnav__item:hover,
    .third-level-subnav__item:hover,
    .second-level-subnav__item:focus,
    .third-level-subnav__item:focus {
        color: #fff;
        display: block;
        text-decoration: none;
    }
    .second-level-subnav__li.is-active .second-level-subnav__item,
    .third-level-subnav__li.is-active .third-level-subnav__item {
        color: var(--color-primary);
    }
}


@media screen and (max-width: 767px) {
    .subnav__li,
    .second-level-subnav__li,
    .third-level-subnav__li{
        position: relative;
    }
    .second-level-subnav__li,
    .third-level-subnav__li{
        display: block;
        position: static;
    }
    .third-level-subnav,
    .second-level-subnav {
        width: 100vw;
        list-style: none;
        margin: 0;
        padding:0;
        display: block;
        position: absolute;
        left:100%;
        top:0;
        bottom:0;
        background-color: #1b1b1b;
        transform: translateX(10px);
        z-index: 10;
        transition: transform 200ms ease-in-out;
    }
    .is-open>.second-level-subnav {
        transform: translateX(-100%);
    }
    .is-open>.third-level-subnav {
        transform: translateX(-100%);
    }
}