.img-grid-slider {
    margin-top: -30px;
}
.img-grid-slider__item {
    margin-top: 30px;
}
.img-grid-slider .slider__arrow {
    width: 35px;
    height: 66px;
    background: #b2b2b2;
    font-size: 24px;
    transition: opacity 250ms ease-in-out;
    margin-top: 15px;
    padding: 5px;
}
.img-grid-slider .slider__arrow.slick-next {
    right: -10px;

    @media screen and (min-width: 768px){
        right: 0;
    }

    @media screen and (min-width: 1460px){
        right: -50px;
    }
}
.rtl-page .img-grid-slider .slider__arrow.slick-next {
    right: auto;
    left: -10px;

    @media screen and (min-width: 768px){
        right: auto;
        left: 0;
    }

    @media screen and (min-width: 1460px){
        right: auto;
        left: -50px;
    }
}
.img-grid-slider .slider__arrow.slick-prev {
    left: -10px;

    @media screen and (min-width: 768px){
        left: 0;
    }

    @media screen and (min-width: 1460px){
        left: -50px;
    }
}
.rtl-page .img-grid-slider .slider__arrow.slick-prev {
    left: auto;
    right: -10px;

    @media screen and (min-width: 768px){
        left: auto;
        right: 0;
    }

    @media screen and (min-width: 1460px){
        left: auto;
        right: -50px;
    }
}
.img-grid-slider .slider__arrow>.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.rtl-page .img-grid-slider .slider__arrow.slick-prev>.icon {
    transform: translateX(-50%) translateY(-50%);
}
.img-grid-slider .slider__arrow.slick-disabled {
    opacity: 0;
    cursor: default;
}