.contact-teaser {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #f1f1f1;
    background: #fff;
    margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
    .contact-teaser--min-height {
        min-height:330px;
    }
    .row--same-height__item .contact-teaser {
        height:100%;
    }
}
.contact-teaser__body {
    padding: 15px 30px;
    font-size: 16px;

    @media screen and (max-width: 767px) {
        padding: 25px;
    }
}
.contact-teaser__body__address {
    margin-top: 25px;
}
.contact-teaser__body__address__block + .contact-teaser__body__address__block {
    margin-top: 8px;
}

.contact-teaser__title {
    color: var(--color-primary);
    text-transform: uppercase;
    font-family: var(--font-bold-family);
}
.contact-prefix {
    width: 20px;
    display: inline-block;
}

.contact-teaser__hidden-mail[hidden] {
    display: none;
}
.contact-teaser__hidden-mail {
    position: relative;
    display: block;
    background: #D9DCDB;
    text-transform: lowercase;
    font-size: inherit;
    color:var(--color-primary);
    padding:13px 40px 13px 10px;
    text-align: center;
    font-family: var(--font-bold-family);

    @media screen and (max-width: 767px) {
        padding-top:9px;
        padding-bottom:9px;
    }
}
.contact-teaser__copy-btn {
    display: block;
    width: 30px;
    height:30px;
    line-height: 30px;
    color:#000;
    background: transparent;
    border:0;
    margin:0;
    padding: 0;
    text-align: center;
    position: absolute;
    right:10px;
    top:50%;
    transform: translateY(-50%);
    -webkit-appearance: none;
}
.contact-teaser__copy-btn:before,
.contact-teaser__copy-btn:after {
    content: '';
    display: block;
    width: 17px;
    height:17px;
    border:2px solid #000;
    border-radius:4px;
    background: #D9DCDB;
    position: absolute;
    left:50%;
    top: 50%;
    transform:translateX(-11px) translateY(-6px);
}
.contact-teaser__copy-btn:after {
    transform:translateX(-5px) translateY(-12px);
}