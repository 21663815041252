.teaser__hl {
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 20px;
    line-height:calc(28/20);
    text-transform: none;

    @media screen and (min-width: 768px){
        font-size: 30px;
        line-height:calc(35/30);
    }
}
.teaser__hl>.text-is-highlight {
    font-family:var(--font-bold-family);
}

.teaser__wysiwyg {
    margin-top: -10px;
    margin-bottom: 25px;
}


/* eyecatching */
.teaser-eye-catching {
    width:100%;
    height:100%;
    padding:40px;
    color:#fff;
    font-family: var(--font-palfinger-family);
    background-color: var(--color-primary);
    background-image: url(/static/img/backgrounds/bg-overlay-red.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
}
.teaser-eye-catching__body {
    font-size:50px;
    line-height:1.1;
}
.teaser-eye-catching__more {
    position: absolute;
    left:40px;
    bottom:40px;
    display: inline-block;
    font-size: 18px;
    color:inherit;
    text-decoration: none;
    border-bottom:3px solid #fff;
}
.teaser-eye-catching__icon {
    position: absolute;
    right:40px;
    bottom:40px;
    display:block;
    font-size: 32px;
    color:inherit;

}