.navbar__search-btn {
    transition: opacity 200ms ease-in-out;
}
.is-open .navbar__search-btn {
    opacity: 0;
    pointer-events: none;
}
@media screen and (max-width: 767px){
    .navbar__search {
        display: block;
        position: fixed;
        top: 60px;
        bottom: 0;
        left: 0;
        right:0;
        padding: 30px 15px;
        transform: translateX(-100%);
        transition: transform 250ms ease-in-out;
        border-top:1px solid #000;
        background: #18191d;
        background: radial-gradient(ellipse at center right,  #313235 0%,#18191d 100%);;
    }
    .is-open.navbar__search {
        transform: translateX(0);
    }
}
@media screen and (min-width: 768px){
    li.navbar__search {
        position: static;
    }
    .navbar__search__toggle {
        position: relative;
        z-index: 1;
        height: 40px;
        padding: 11px 15px;
        text-align: right;
    }
    .is-open .navbar__search__toggle__icon {
        font-size: 14px;
        padding: 0 5px;
    }
    .is-open .navbar__search__toggle__icon:before {
        content: var(--icon-close);
    }
}
.navbar__search__form {
    position: relative;

  @media screen and (min-width: 768px){
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    width: 72%;
    max-width: 1000px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 250ms ease-in-out;
  }

  @media screen and (min-width: 1400px){
    width: 100%;
  }
}
@media screen and (min-width: 768px){
    .rtl-page .navbar__search__form {
        right: auto;
        left: 35px;
    }
}
@media screen and (min-width: 768px){
    .is-open.navbar__search__form{
        opacity: 1;
        visibility: visible;
    }
}
.navbar__search__form--sm {
    @media screen and (min-width: 768px){
        max-width: 500px;
    }

}
.navbar__search__form__input {
    background: var(--color-dark);
    border: 1px solid #353535;
    border-radius: 100px;
    width: 100%;
    padding: 0 40px;
    font-family:var(--font-regular-family);
    font-weight: 400;
    height: 40px;
}
.navbar__search__form__submit {
    background: transparent;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 8px 15px;
    font-size: 14px;
}
.rtl-page .navbar__search__toggle.is-open {
    position: absolute;
    left:55px;
}
.rtl-page .navbar__search__form__submit {
    left: auto;
    right: 0;
}

.navbar__search__form__input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #fff;
}
.navbar__search__form__input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
    opacity:  1;
}
.navbar__search__form__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}