.dropdown-menu--langs {
    padding: 20px 25px;
    border: 1px solid var(--color-grey-light);
}

.dropdown-menu--langs > li > a {
    padding: 6px;
}

.dropdown-menu--full-width {
    width: 100%;
}