.navbar-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    border: 0;
    color: #fff;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    margin: 0;

    @media screen and (min-width: 768px){
        height: 120px;
    }
    @media screen and (min-width: 1920px) {
        max-width: 1920px;
        margin: 0 auto;
    }
}
.navbar-nav>li>a:hover,
.navbar-nav>li>a:focus {
    background: transparent;
    color: var(--color-secondary);
}

.navbar-nav > li {
    @media screen and (min-width: 768px){
        padding: 0 10px 15px;
    }
    @media screen and (min-width: 1440px){
        padding: 0 20px 15px;
    }
}
.navbar-nav>li>a {
    font-size: 16px;

    @media screen and (min-width: 786px){
        padding: 10px 0 4px;
    }

    @media screen and (min-width: 1200px){
        font-size: 18px;
    }

    @media screen and (min-width: 1440px){
        font-size: 20px;
    }
}

.navbar__top .navbar-nav > li {
    @media screen and (min-width: 768px){
        padding: 0 20px 0;
    }
}
.navbar__top li>a {
    font-size: 14px;

    @media screen and (min-width: 1200px){
        font-size: 16px;
    }

}

@media screen and (min-width: 768px){
    .rtl-page .navbar__top .navbar-nav.navbar-right {
        float:left !important;
    }
}

/*navbar header*/
.navbar-brand {
    max-width: 150px;

    @media screen and (min-width: 768px) {
        padding: 0;
        max-width: 159px;
        height: auto;
        position: absolute;
        left: 40px;
        top: 50%;
        z-index: 1;
        transform: translateY(-35px);
        transition: transform 200ms ease-in-out;
    }

    @media screen and (min-width: 1200px) {
        max-width: 183px;
    }
}

/* 90 years logo */
@media screen and (max-width: 767px) {
    .navbar-brand.navbar-brand--90years {
        padding-top:11px;
    }
}
@media screen and (min-width: 1280px) {
    .navbar-brand.navbar-brand--90years {
        transform: translateY(-50px);
    }
    .affix-nav-bar.is-affix .navbar-brand.navbar-brand--90years {
        transform: translateY(-50%);
    }

}
.navbar-brand.navbar-brand--90years,
.navbar-brand.navbar-brand--90years img {
    width:180px;
    max-width: initial;
    height:auto;

    @media screen and (min-width: 1025px) and (max-width: 1279px) {
        width: 200px;
        max-width: initial;
    }

    @media screen and (min-width: 1280px) {
        width: 330px;
        max-width: initial;
    }
}

@media screen and (min-width: 768px){
    .is-transitioning .navbar-brand,
    .is-affix .navbar-brand {
        transform: translateY(-50%);
    }

    .navbar-main .navbar-collapse {
        padding-right: 40px;
        padding-left: 40px;
    }
    .navbar-brand.navbar-brand--paldrive {
        transform: translateY(-50%);
    }
}
.navbar-brand.navbar-brand--paldrive-logo {
    left: 220px;
    @media screen and (min-width: 768px) {
        left: 240px;
    }
    @media screen and (min-width: 1200px) {
        left: 280px;
    }
}
.navbar__top {
    position: relative;
    z-index: 1;
    margin-top: 10px;

    @media screen and (min-width: 768px){
        padding: 0 40px;
    }
}

.navbar__top.no-navbar {
    @media screen and (min-width: 768px){
        height: 40px;
    }
}

.navbar-brand img {
    max-width: 100%;
    height: auto;
    width: 150px;

    @media screen and (min-width: 768px) {
        width: 159px;
    }

    @media screen and (min-width: 1200px) {
        width: 183px;
    }
}

.navbar-brand.logo-override {
    @media screen and (min-width: 1200px) {
        max-width: 300px;
    }
}

.navbar-brand.logo-override img {
    @media screen and (min-width: 1200px) {
        width: 300px;
    }
}

.navbar-brand .navbar-paldrive-logo {
    max-width: 70px;
    @media (min-width: 768px) {
        max-width: 115px;
    }
}

/*navbar search*/
.navbar__search-btn {
    font-size: 24px;
    padding: 10px 5px 9px;
}

/*affix*/
@media screen and (min-width: 768px) {
    .affix-nav-bar {
        position: relative;
        background: rgba(27, 27, 27, 0);
        transition: background 200ms ease-in-out;
        height: 80px;
        padding: 17px 0;
    }
    .affix-nav-bar.is-transitioning {
        position: absolute;
        left: 0;
        right: 0;
        background: var(--color-dark);
    }
    .affix-nav-bar .navbar-collapse {
        transform: translateY(-10px);
        transition: transform 200ms ease-in-out;
    }
    .affix-nav-bar.is-affix .navbar-collapse {
        transform: translateY(0);
    }
}

.affix-nav-bar.is-affix {
    @media screen and (min-width: 768px) {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        background: var(--color-dark);
        background-image: url(/static/img/backgrounds/bg-overlay-grey.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    @media screen and (min-width: 1920px) {
        left: 50%;
        margin-left: -960px;
        right: auto;
        width: 1920px;
    }
}

@media screen and (min-width: 768px) {
    .navbar--paldrive.navbar-main {
        height: auto;
    }

    .navbar--paldrive .affix-nav-bar {
        background: var(--color-dark);
        background-image: url(/static/img/backgrounds/bg-overlay-grey.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
}


.navbar-right__home {
    font-size: 12px;
}

/*country toggle*/
.navbar-nav__country {
    background: transparent;
    border: 0;
    padding: 5px 15px;
}
.navbar-nav__country>.sprite {
    margin-right: 4px;
    margin-left: 4px;
}

/*active state*/
.navbar-main .active>a {
    color: var(--color-secondary);
    border-bottom:2px solid var(--color-secondary);
}

@media screen and (min-width: 768px) {
    .modal-open .navbar.navbar-main {
        right: 17px;
    }
}

.navbar-main-container{
    position: relative;
}