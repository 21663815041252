.panel-default .panel-title a {
    color: #fff;
    background: var(--color-primary);
    display: block;
    transition: background 250ms ease-in-out, color 250ms ease-in-out;
    padding: 15px 35px 15px 15px;
}
@media screen and (min-width: 768px){
    .panel-default .panel-title a {
        padding: 30px;
    }
}
.panel-default .panel-title .panel-title__subtitle {
    font-family: var(--font-regular-family);
    font-size: 14px;
}
.panel-default .panel-title a.collapsed {
    background: #f1f1f1;
    color: var(--color-dark);
}
.panel-title__icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
}
.rtl-page .panel-title__icon {
    right:auto;
    left: 15px;
}
@media screen and (min-width: 768px){
    .panel-title__icon {
        right: 30px;
        font-size: 16px;
    }
    .rtl-page .panel-title__icon {
        right:auto;
        left: 30px;
    }
}
.panel-title__icon:before {
    display: block;
    transform: scaleY(-1);
    transition: transform 250ms ease-in-out;
    transform-origin: center;
}
.collapsed .panel-title__icon:before {
    transform: scaleY(1);
}
@media screen and (min-width: 768px) {
    .panel-collapse:target {
        padding-top:80px;
        margin-top: -80px;
    }
}