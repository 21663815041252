.teaser-slider {
    margin: 20px 0 50px;
}
.teaser-slider__item {
    padding: 10px 5px;
}

.teaser-slider.margin-top-0{
    margin-top: 0;
}

@media screen and (min-width: 768px){
    .teaser-slider {
        margin: 85px -15px 0;
    }
    .teaser-slider__item {
        padding: 0 15px;
    }
}
.teaser-slider,
.teaser-slider-xs {
    clear: both;
    padding-bottom: 80px;
    position: relative;
}
@media screen and (max-width: 767px) {
    .slider-static-dots .teaser-slider {
        margin-bottom: 20px;
    }
}